import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  Input,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import MainContainer from "../../layout/MainContainer";
import { Autocomplete } from "@react-google-maps/api";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { UploadMedia, UploadVideo } from "../../utils/uploadMedia";
import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";

import { useFormik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CommonBody, PropertyType, PropertyValues } from "../../types/General";
import {
  useEditPropertyMutation,
  useLazyGetSpecificPropertyByIdQuery,
  usePostAddPropertyMutation,
  useLazyGetpropertYTypeListingQuery,
  useLazyGetAmenitiesListingQuery,
} from "../../services/property";

import { useLazyGetAllCategoryQuery } from "../../services/category";
import Loader from "../../helpers/constants/Loader";
import {
  useLazyGetApprovedAgentQuery,
  useLazyGetApprovedCompanyQuery,
} from "../../services/users";
import { useLazyGetAllpropertyStatusQuery } from "../../services/propertyStatus";

const AddProperty = () => {
  const [ByIdDetails, setByIdDetails] = useState<PropertyValues | any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { _id } = useParams();

  const [addProperties] = usePostAddPropertyMutation();
  const [updateProperty] = useEditPropertyMutation();
  const [fetchApi] = useLazyGetSpecificPropertyByIdQuery();
  const [propertyTypeList] = useLazyGetpropertYTypeListingQuery();
  const [amenitiesTypeList] = useLazyGetAmenitiesListingQuery();
  const [propertyTypeId, setPropertyType] = useState<
    { _id: string; name: string }[]
  >([]);

  const [categories, setCategories] = useState<{ _id: string; name: string }[]>(
    []
  );
  const [amennity, setAmenity] = useState<{ _id: string; name: string }[]>([
    {
      _id: "",
      name: "",
    },
  ]);
  console.log("amennity :", amennity);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [uploadReels, setUploadReels] = useState<string>("");

  const [autocomplete, setAutocomplete] = useState<any>();

  const [imageError, setImageError] = useState("");
  const [videoError, setVideoError] = useState("");
  const [openAssignAgent, setOpenAssignAgent] = useState(false);
  const [fetchAllCategory] = useLazyGetAllCategoryQuery();

  const handlePropertyTypeChange = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      formik.setFieldValue("propertyTypeId", event.target?.value);
    }
  };

  const handleAmenities = (event: SelectChangeEvent<string[]>) => {
    if (event?.target?.value) {
      formik.setFieldValue("amenitiesId", event.target?.value);
    }
  };

  const handlePropertyCondition = (event: SelectChangeEvent) => {
    formik.setFieldValue("propertyCondition", event.target?.value);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("IMAGGGGGG");
    const files: any = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      const imageUrl = URL.createObjectURL(file);
      if (uploadedImages.includes(imageUrl)) {
        setImageError("This image has already been uploaded.");
        (event.target as HTMLInputElement).value = "";
        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const uploadedImageUrl = res?.data[0]?.location;
        setUploadedImages((prev) => [...prev, uploadedImageUrl]);
        setImageError("");
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    } finally {
      (event.target as HTMLInputElement).value = "";
    }
  };

  const handleReelsUploads = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("Hitttt");
    const files: FileList | null = event.target.files;
    const file = files?.length ? files[0] : null;

    console.log(file, "FILES");

    if (!file) {
      setVideoError("No file selected.");
      return;
    }

    const allowedTypes = ["video/mp4", "video/webm", "video/ogg"];
    if (!allowedTypes.includes(file.type)) {
      setVideoError(
        "Invalid file type. Please upload a video in MP4, WebM, or Ogg format."
      );
      (event.target as HTMLInputElement).value = "";
      return;
    }

    // Create a URL object for the video file
    const videoUrl = URL.createObjectURL(file);

    try {
      // Create a hidden video element to check the duration
      const videoElement = document.createElement("video");
      videoElement.src = videoUrl;

      // Listen for the metadata to be loaded
      videoElement.onloadedmetadata = async () => {
        // Check if the video duration is longer than 30 seconds
        if (videoElement.duration > 30) {
          setVideoError(
            "Video duration exceeds 30 seconds. Please upload a shorter video."
          );
          (event.target as HTMLInputElement).value = "";
          URL.revokeObjectURL(videoUrl); // Clean up the object URL
          return;
        }

        // If duration is valid, proceed with upload
        setLoading(true);
        if (uploadReels.includes(videoUrl)) {
          setVideoError("This video has already been uploaded.");
          (event.target as HTMLInputElement).value = "";
          URL.revokeObjectURL(videoUrl);
          return;
        }

        // Attempt to upload the video file
        const res = await UploadVideo(file);

        if (res?.statusCode === 200) {
          setLoading(false);
          const uploadedReelsUrl = res?.data[0]?.location;
          setUploadReels(uploadedReelsUrl);
          setVideoError("");
          successToast("Reel uploaded successfully.");
        } else {
          setLoading(false);
          errorToast(
            res?.message || "Failed to upload video. Please try again."
          );
        }

        URL.revokeObjectURL(videoUrl);
      };
    } catch (error: any) {
      setLoading(false);
      console.error("Error uploading video:", error);
      errorToast(
        error?.data?.message ||
          "An unexpected error occurred while uploading the video."
      );
    } finally {
      (event.target as HTMLInputElement).value = "";
    }
  };

  const removeImage = (index: number) => {
    setUploadedImages((prev) => prev?.filter((_, i) => i !== index));
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any)?.getPlace();

      if (place && place?.address_components) {
        let address = place?.address_components;

        let state,
          city,
          country,
          zip = "";

        address?.forEach(function (component: any) {
          let types = component?.types;

          if (
            types?.indexOf("locality") > -1 ||
            types?.indexOf("administrative_area_level_3") > -1
          ) {
            city = component?.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component?.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place?.geometry?.location?.lat();
        var lng = place?.geometry?.location?.lng();
        formik.setFieldValue("address", place?.formatted_address);
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
      }
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({
        userId: id,
        isDraft: Boolean(searchParams?.get("draft")),
      }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || []);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchCategories = async () => {
    try {
      const response = await fetchAllCategory({
        limit: 50,
        page: 1,
        search: "",
        type: "subcategory",
        parentId: "",
      }).unwrap();
      setCategories(response?.data?.category || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchPropertyType = async () => {
    try {
      const response = await propertyTypeList({}).unwrap();
      console.log("response :", response);
      setPropertyType(response?.data?.propertyType || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchAmenities = async () => {
    try {
      const response = await amenitiesTypeList({}).unwrap();
      setAmenity(response?.data || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };
  const [rows, setRows] = useState<PropertyType[]>([]);
  const [fetchAll] = useLazyGetAllpropertyStatusQuery();
  const List = async (search: string) => {
    try {
      const response = await fetchAll({ search: search }).unwrap();
      setRows(response?.data?.PropertyCondition || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      address: "",
      specifications: "",
      keyFeature: "",
      beds: "",
      baths: "",
      advertisementPermitNo: "",
      categoryId: [],
      category: "",
      size: "",
      propertyCondition: "",
      amenitiesId: [],
      latitude: "",
      longitude: "",
      propertyTypeId: "",
      area: "",
      companyName: "",
      agentName: "",
      agentId: "",
      companyId: "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Property Name is required"),
      price: Yup.string()
        .required("Price is required")
        .max(10, "Price must be less than 10 characters"),
      address: Yup.string().required("Address is required"),
      area: Yup.string().required("Area is required"),
      specifications: Yup.string().required("Specifications is required"),
      keyFeature: Yup.string().required("Key Feature is required"),
      propertyCondition: Yup.string().required(
        "Please select Property Condition"
      ),
      companyName: Yup.string().required("Please select a company"),
      propertyTypeId: Yup.string().required("Please select Property Type"),
      category: Yup.string().required("Category is required"),
      amenitiesId: Yup.array()
        .min(1, "Please select amenity")
        .required("Amenities are required"),

      beds: Yup.number().required("Beds is required"),
      baths: Yup.number().required("Baths is required"),
      advertisementPermitNo: Yup.string()
        .max(20)
        .required("Advertisement Permit No is required"),
    }),

    onSubmit: async (values: any) => {
      formik.setSubmitting(true);

      if (uploadedImages?.length == 0) {
        errorToast("Please upload an image");
        return;
      }
      const body: any = {};

      if (values?.name) {
        body.name = values.name;
      }
      if (values?.price) {
        body.price = values.price;
      }
      if (values?.address) {
        body.address = values.address;
      }
      if (values?.specifications) {
        body.specifications = values.specifications;
      }
      if (values?.keyFeature) {
        body.keyFeature = values.keyFeature;
      }
      if (values?.beds) {
        body.beds = values.beds;
      }
      if (values?.baths) {
        body.baths = values.baths;
      }
      if (values?.advertisementPermitNo) {
        body.advertisementPermitNo = values.advertisementPermitNo;
      }
      if (values?.area) {
        body.area = values.area;
      }
      if (values?.category) {
        body.categoryId = values.category;
      }
      if (uploadedImages?.length) {
        body.images = uploadedImages;
      }
      if (uploadReels?.length) {
        body.reel = uploadReels;
      }
      if (values?.propertyCondition) {
        body.propertyConditionId = values.propertyCondition;
      }
      if (values?.amenitiesId) {
        body.amenitiesId = values.amenitiesId;
      }
      if (values?.latitude) {
        body.latitude = values.latitude;
      }
      if (values?.longitude) {
        body.longitude = values.longitude;
      }
      if (values?.propertyTypeId) {
        body.propertyTypeId = values.propertyTypeId;
      }
      if (companyId) {
        body.companyId = companyId;
      }
      if (selectedAgentId) {
        body.agentId = selectedAgentId;
      }

      if (searchParams.get("draft")) {
        body["draftId"] = _id;
      }
      console.log("property_body1", body);
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      try {
        if (!_id || Boolean(searchParams.get("draft"))) {
          const response = await addProperties(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Properties Added Successfully.");
            navigate("/property-listing");
          }
        } else {
          const response = await updateProperty({
            id: _id,
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Property Updated Successfully.");
            navigate("/property-listing");
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });
  const saveDraft = async () => {
    const values = formik?.values;
    const body: any = {};

    if (values?.name) {
      body.name = values.name;
    }
    if (values?.price) {
      body.price = values.price;
    }
    if (values?.address) {
      body.address = values.address;
    }
    if (values?.specifications) {
      body.specifications = values.specifications;
    }
    if (values?.keyFeature) {
      body.keyFeature = values.keyFeature;
    }
    if (values?.beds) {
      body.beds = values.beds;
    }
    if (values?.baths) {
      body.baths = values.baths;
    }
    if (values?.advertisementPermitNo) {
      body.advertisementPermitNo = values.advertisementPermitNo;
    }
    if (values?.area) {
      body.area = values.area;
    }
    if (values?.category) {
      body.categoryId = values.category;
    }
    if (uploadedImages?.length) {
      body.images = uploadedImages;
    }
    if (uploadReels?.length) {
      body.reel = uploadReels;
    }
    if (values?.propertyCondition) {
      body.propertyConditionId = values.propertyCondition;
    }
    if (values?.amenitiesId) {
      body.amenitiesId = values.amenitiesId;
    }
    if (values?.latitude) {
      body.latitude = values.latitude;
    }
    if (values?.longitude) {
      body.longitude = values.longitude;
    }
    if (values?.propertyTypeId) {
      body.propertyTypeId = values.propertyTypeId;
    }
    if (companyId) {
      body.companyId = companyId;
    }
    if (selectedAgentId) {
      body.agentId = selectedAgentId;
    }

    // Always set isDraftSaved to true
    body.isDraftSaved = true;

    console.log("property_body1", body);
    const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

    try {
      if (!_id) {
        const response = await addProperties(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Properties drafted successfully.");
          navigate("/property-listing");
        }
      } else {
        const response = await updateProperty({
          id: _id,
          body: encryptedBody,
        }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Property drafted successfully.");
          navigate("/property-listing");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    } finally {
      formik.setSubmitting(false);
    }
  };
  const [getApprovedCompany] = useLazyGetApprovedAgentQuery();
  const [agentsData, setAgentsData] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const fetchApproved = async (role: number, page: number, search: string) => {
    setLoading(true);
    try {
      console.log("companyId :", companyId);
      const response = await getApprovedCompany({
        page: page,
        search: "",
        limit: 200,
        parentId: companyId,
      }).unwrap();
      if (response.statusCode === 200) {
        setLoading(false);
        setAgentsData(response?.data?.user);
        if (!response?.data?.user?.length)
          errorToast("This company dont have any agents");
        console.log("response?.data :", response?.data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchAmenities();
    fetchPropertyType();
    fetchCompanyListing();
    List("");
  }, []);
  useEffect(() => {
    if (_id) fetchDataById(_id);
  }, [_id]);
  useEffect(() => {
    if (ByIdDetails) {
      // @ts-ignore
      formik.setValues({
        name: ByIdDetails?.name || "",
        price: ByIdDetails?.price || "",
        address: ByIdDetails?.address || "",
        specifications: ByIdDetails?.specifications || "",
        keyFeature: ByIdDetails?.keyFeature || "",
        propertyCondition: ByIdDetails?.propertyConditionId || "",
        propertyTypeId: ByIdDetails?.propertyTypeId?._id || "",
        categoryId: ByIdDetails?.categoryId?._id || "",
        category: ByIdDetails?.categoryId?._id || "",
        area: ByIdDetails?.area,
        amenitiesId:
          ByIdDetails?.amenities?.map((item: { _id: any }) => item?._id) || [],
        beds: ByIdDetails?.beds || "",
        baths: ByIdDetails?.baths || "",
        advertisementPermitNo: ByIdDetails?.advertisementPermitNo || "",
        latitude: ByIdDetails?.latitude || "",
        longitude: ByIdDetails?.longitude || "",
        companyName: ByIdDetails?.companyId || "",
        agentName: ByIdDetails?.agentId || "",
      });
      setSelectedAgentId(ByIdDetails?.agentId || "");
      setCompanyId(ByIdDetails?.companyId || "");
      setUploadedImages(ByIdDetails?.images || []);
      setUploadReels(ByIdDetails?.reel);
    }
  }, [ByIdDetails]);
  const [companyListingApi] = useLazyGetApprovedCompanyQuery();
  const [companyListing, setCompanyListing] = useState<any>([]);
  const [companyId, setCompanyId] = useState<string>("");
  console.log(companyListing, "companyListing");

  const fetchCompanyListing = async () => {
    try {
      const res = await companyListingApi({
        page: 1,
        search: "",
        limit: 100,
      }).unwrap();
      if (res?.statusCode === 200) {
        setCompanyListing(res?.data?.user);

        console.log(res?.data?.user, "res?.data?.user");
      }
    } catch (error: any) {
      console.log(error?.data?.message);
    }
  };
  useEffect(() => {
    if (companyId) fetchApproved(3, 1, "");
  }, [companyId]);
  console.log(formik?.values?.amenitiesId, "sdds");
  console.log(formik.values.beds, "bedsss");

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <Loader loading={isLoading} />
          <h1 className="mn_hdng" style={{ marginRight: "auto" }}>
            {_id ? "Edit Property" : "Add Property"}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() =>
              navigate(
                "/property-listing" +
                  (Boolean(searchParams.get("draft")) ? "?draft=true" : ""),
                { replace: true }
              )
            }
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    className="custom_label"
                    style={{ padding: "10px" }}
                  >
                    Images
                  </Typography>
                  <Box className="upload_images_container">
                    {uploadedImages?.length
                      ? uploadedImages?.map((image, index) => (
                          <div key={index} className="upload_image_preview">
                            <CardMedia
                              style={{
                                width: "300px",
                                height: "200px",
                                borderRadius: "5px",
                                margin: "10px",
                              }}
                              component="img"
                              image={image}
                              alt={`uploaded_image_${index}`}
                            />
                            <CancelIcon
                              onClick={(e) => {
                                e.preventDefault();
                                removeImage(index);
                              }}
                            />
                          </div>
                        ))
                      : null}
                    {uploadedImages?.length < 20 && (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button
                            component="span"
                            className="upload_image_btn"
                            style={{ width: "300px", height: "200px" }}
                          >
                            <img
                              style={{
                                borderRadius: 0,
                                width: "300px",
                                height: "200px",
                              }}
                              src="/static/images/product1.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                        {imageError && (
                          <Typography color="error" variant="caption">
                            {imageError}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Property Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Property Name"
                    value={formik?.values?.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name &&
                      typeof formik.errors.name === "string"
                        ? formik.errors.name
                        : undefined
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Advertisement Permit Number
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="advertisementPermitNo"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Advertisement Permit No "
                    value={formik?.values?.advertisementPermitNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.advertisementPermitNo &&
                      Boolean(formik.errors.advertisementPermitNo)
                    }
                    helperText={
                      formik.touched.advertisementPermitNo &&
                      typeof formik.errors.advertisementPermitNo === "string"
                        ? formik.errors.advertisementPermitNo
                        : undefined
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Price</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Price"
                    value={formik?.values?.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={
                      formik.touched.price &&
                      typeof formik.errors.price === "string"
                        ? formik.errors.price
                        : undefined
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <div className="control_group w_50">
                    <Typography className="custom_label">Address</Typography>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                      options={{
                        types: ["political"],
                        componentRestrictions: { country: "ae" },
                    }}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        name="address"
                        value={formik?.values?.address || ""}
                        placeholder="Enter Address"
                        fullWidth
                        helperText={
                          formik.touched.address &&
                          typeof formik.errors.address === "string"
                            ? formik.errors.address
                            : undefined
                        }
                        onChange={(e) => {
                          if (
                            e.target?.value.trim() === "" ||
                            e.target?.value === "."
                          ) {
                            formik.setFieldValue("address", "");
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Autocomplete>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Beds</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="beds"
                    fullWidth
                    value={formik?.values?.beds || ""}
                    onChange={(e) =>
                      formik.setFieldValue("beds", e.target?.value)
                    }
                    onBlur={formik.handleBlur}
                    error={formik.touched?.beds && Boolean(formik.errors?.beds)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7+</MenuItem>
                  </Select>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Bathrooms</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="baths"
                    fullWidth
                    value={formik?.values?.baths || ""}
                    onChange={(e) =>
                      formik.setFieldValue("baths", e.target?.value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.baths && Boolean(formik.errors?.baths)
                    }
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7+</MenuItem>
                  </Select>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">
                      Property Type
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="propertyTypeId"
                      value={formik?.values?.propertyTypeId || ""}
                      onChange={handlePropertyTypeChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.propertyTypeId &&
                        Boolean(formik.errors?.propertyTypeId)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {propertyTypeId?.length > 0
                        ? propertyTypeId?.map((it: any) => (
                            <MenuItem key={it?._id} value={it?._id}>
                              {it?.name}
                            </MenuItem>
                          ))
                        : ""}
                    </Select>

                    {formik.touched?.propertyTypeId &&
                      typeof formik?.errors?.propertyTypeId === "string" && (
                        <Typography
                          sx={{ fontSize: "14px" }}
                          variant="caption"
                          color="error"
                        >
                          {formik.errors?.propertyTypeId}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Amenities</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="amenitiesId"
                      value={formik?.values?.amenitiesId || []}
                      onChange={handleAmenities}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.amenitiesId &&
                        Boolean(formik.errors?.amenitiesId)
                      }
                      displayEmpty
                      multiple
                      renderValue={(selected) =>
                        selected?.length > 0
                          ? selected
                              ?.map((value) => {
                                const amenity = amennity?.find(
                                  (it) => it?._id === value
                                );
                                return amenity?.name;
                              })
                              .join(", ")
                          : "Select"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {amennity?.length > 0
                        ? amennity?.map((it) => (
                            <MenuItem key={it?._id} value={it?._id}>
                              {it?.name}
                            </MenuItem>
                          ))
                        : ""}
                    </Select>
                    {formik.touched.amenitiesId &&
                      typeof formik.errors.amenitiesId === "string" && (
                        <Typography
                          sx={{ fontSize: "14px" }}
                          variant="caption"
                          color="error"
                        >
                          {formik.errors.amenitiesId}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">
                      Property Status
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="propertyCondition"
                      value={formik?.values?.propertyCondition || ""}
                      onChange={handlePropertyCondition}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.propertyCondition &&
                        Boolean(formik.errors.propertyCondition)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {rows?.length &&
                        rows?.map((data: any) => {
                          return (
                            <MenuItem value={data?._id}>{data?.name}</MenuItem>
                          );
                        })}
                    </Select>
                    {formik.touched.propertyCondition &&
                      typeof formik.errors.propertyCondition === "string" && (
                        <Typography
                          sx={{ fontSize: "14px" }}
                          variant="caption"
                          color="error"
                        >
                          {formik.errors.propertyCondition}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Category</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="category"
                      value={formik?.values?.category || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {categories?.length > 0
                        ? categories?.map((category) => (
                            <MenuItem key={category?._id} value={category?._id}>
                              {category?.name}
                            </MenuItem>
                          ))
                        : ""}
                    </Select>
                    {formik.touched?.category && formik.errors?.category && (
                      <Typography
                        sx={{ fontSize: "14px" }}
                        variant="caption"
                        color="error"
                      >
                        Please select a category
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Area (Sq.ft)</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="area"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter area"
                    value={formik?.values?.area}
                    onChange={(e) => {
                      const re = /^[0-9]*\.?[0-9]*$/;
                      if (e.target?.value === "" || re.test(e.target?.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.area && Boolean(formik.errors.area)}
                    inputProps={{
                      inputMode: "decimal",
                    }}
                    helperText={
                      Boolean(formik.touched.area) &&
                      formik.errors.area?.toString()
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}></Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Key Feature</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="keyFeature"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Enter Key Feature"
                    value={formik?.values?.keyFeature}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.keyFeature &&
                      Boolean(formik.errors.keyFeature)
                    }
                    helperText={
                      Boolean(formik.touched.keyFeature) &&
                      formik.errors.keyFeature?.toString()
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Specifications
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="specifications"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Enter Specifications"
                    value={formik?.values?.specifications}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.specifications &&
                      Boolean(formik.errors.specifications)
                    }
                    helperText={
                      formik.touched.specifications &&
                      typeof formik.errors.specifications === "string"
                        ? formik.errors.specifications
                        : undefined
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography className="custom_label">Company Name</Typography>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="companyName"
                    fullWidth
                    value={formik?.values?.companyName || ""}
                    onChange={(e) =>
                      formik.setFieldValue("companyName", e.target?.value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.companyName &&
                      Boolean(formik.errors?.companyName)
                    }
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {companyListing?.length &&
                      companyListing?.map((item: any) => {
                        return (
                          <MenuItem
                            onClick={() => setCompanyId(item?._id)}
                            value={item?._id}
                          >
                            {item?.companyName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {formik.touched?.companyName &&
                    formik.errors?.companyName && (
                      <Typography
                        sx={{ fontSize: "14px" }}
                        variant="caption"
                        color="error"
                      >
                        Please select a company
                      </Typography>
                    )}
                </Grid>
                {agentsData?.length ? (
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Assign Aggent
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="agentName"
                      fullWidth
                      value={formik?.values?.agentName || ""}
                      onChange={(e) =>
                        formik.setFieldValue("agentName", e.target?.value)
                      }
                      onBlur={formik.handleBlur}
                      // error={
                      //   formik.touched?.agentName &&
                      //   Boolean(formik.errors?.agentName)
                      // }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {agentsData?.length &&
                        agentsData?.map((item: any) => {
                          return (
                            <MenuItem
                              onClick={() => setSelectedAgentId(item?._id)}
                              value={item?._id}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                ) : (
                  ""
                )}
                {/* <Grid item xs={12}>
                  <Typography className="custom_label">Upload Reels</Typography>
                  <Box className="upload_images_container">
                    {uploadReels?.length ? (
                      <div className="upload_image_preview2">
                        <video controls width="100%" height="100%">
                          <source src={uploadReels} type="video/mp4" />
                        </video>
                        <CancelIcon onClick={() => setUploadReels("")} />
                      </div>
                    ) : null}
                    {uploadReels?.length < 2 && (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file-vdo">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file-vdo"
                            type="file"
                            inputProps={{
                              accept: "video/*",
                            }}
                            onChange={handleReelsUploads}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                            {/* <CameraAltIcon /> */}
                {/* </Button>
                        </label>
                        {videoError && (
                          <Typography color="error" variant="caption">
                            {videoError}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid> */}
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
                {Boolean(searchParams?.get("draft")) && (
                  <Button
                    size="large"
                    className="btn btn_primary"
                    onClick={saveDraft}
                  >
                    Save as draft
                  </Button>
                )}
                {!_id && (
                  <Button
                    size="large"
                    className="btn btn_primary"
                    onClick={saveDraft}
                  >
                    Save as draft
                  </Button>
                )}
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddProperty;
