import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";


import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers";

import Loader from "../../helpers/constants/Loader";
import { useEditpropertyTypeMutation, useLazyGetSpecificpropertyTypeByIdQuery, usePostAddpropertyTypeMutation } from "../../services/propertyType";
import { features } from "process";

const AddPropertyType = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState("");
  const [fetchApi, { data }] = useLazyGetSpecificpropertyTypeByIdQuery();
  const [fetchAddCategory, { isLoading }] = usePostAddpropertyTypeMutation();
  const [updateCategory] = useEditpropertyTypeMutation();




  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setProfilePicture(response.data.image || "");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      token: "",
      premium: "",
      featured: ""
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      token: Yup.string()
        .required("This field is required")
        .max(10, "Maximum 10 characters are allowed"),
      premium: Yup.string()
        .required("This field is required")
        .max(10, "Maximum 10 characters are allowed"),
      featured: Yup.string()
        .required("This field is required")
        .max(10, "Maximum 10 characters are allowed")

    }),
    onSubmit: async (values) => {


      const body = {
        name: values.title,
        token: Number(values.token),
        premiumPercentage: Number(values.premium),
        featuredPercentage: Number(values.featured)

      };
      console.log(body, "body");

      if (!_id)
        try {
          const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await fetchAddCategory(encryptedBody).unwrap();
          if (response.statusCode === 200) {
            successToast(response.data.message || "Property Type Added Successfully");
            navigate("/manage-propertyType/");
          } else {
            console.log("errrrrrrrr", response)
            errorToast("error")
          }
        } catch (e: any) {
          console.log("error0000", e)
          errorToast(e?.data?.message);
        }

      else {
        try {
          const encryptedBody2 = generateEncryptedKeyBody(body) as CommonBody;
          console.log('Encrypted body:', encryptedBody2);
          const response = await updateCategory({
            id: _id,
            body: encryptedBody2,
          }).unwrap();
          console.log('Response:', response);
          if (response.statusCode === 200) {
            successToast("Property Type Updated Successfully");
            navigate("/manage-propertyType/");
          } else {
            console.log(response, "00000000")
            errorToast(response?.message)
          }
        } catch (e: any) {
          console.log("Error1:", e);
          errorToast(e?.message);
        }


      }

    },
  });

  useEffect(() => {
    if (data && data.statusCode === 200) {
      formik.setValues({
        title: data?.data?.name || "",
        token: data?.data?.settings?.token || "",
        premium: data?.data?.settings?.premiumPercentage || "",
        featured: data?.data?.settings?.featuredPercentage || ""
      });
    }
  }, [data]);
  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);


  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{_id ? "Edit Property Type" : "Add Property Type"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-propertyType");
              }}
            >
              Back
            </Button>
          </div>
          <Loader isLoad={isLoading} />
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>

                  <Grid item xs={4}>
                    <Typography className="custom_label" style={{ paddingTop: 20 }}>
                      Property Type Name
                    </Typography>
                    <TextField
                      hiddenLabel

                      type={"text"}
                      name="title"
                      inputProps={{ maxLength: 40 }}
                      variant="outlined"
                      fullWidth
                      value={formik.values.title}
                      placeholder="Property Type Name"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.title && formik.errors.title
                          ? formik.errors.title.toString()
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label" style={{ paddingTop: 20 }}>
                      Tokens
                    </Typography>
                    <TextField
                      hiddenLabel

                      type={"text"}
                      name="token"
                      inputProps={{ maxLength: 10 }}
                      variant="outlined"
                      fullWidth
                      value={formik.values.token}
                      placeholder="Tokens"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.token && formik.errors.token
                          ? formik.errors.token.toString()
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <Typography className="custom_label" style={{ paddingTop: 20 }}>
                      Premium
                    </Typography>
                    <TextField
                      hiddenLabel

                      type={"text"}
                      name="premium"
                      inputProps={{ maxLength: 10 }}
                      variant="outlined"
                      fullWidth
                      value={formik.values.premium}
                      placeholder="Enter here"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        const regex = /^[0-9]*\.?[0-9]*$/;
                        if (regex.test(e.target.value) || e.target.value === "") {
                          formik.setFieldValue("premium", e.target.value);
                        }
                      }}
                      helperText={
                        formik.touched.premium && formik.errors.premium
                          ? formik.errors.premium.toString()
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label" style={{ paddingTop: 20 }}>
                      Featured
                    </Typography>
                    <TextField
                      hiddenLabel

                      type={"text"}
                      name="featured"
                      inputProps={{ maxLength: 10 }}
                      variant="outlined"
                      fullWidth
                      value={formik.values.featured}
                      placeholder="Enter here"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        const regex = /^[0-9]*\.?[0-9]*$/;
                        if (regex.test(e.target.value) || e.target.value === "") {
                          formik.setFieldValue("featured", e.target.value);
                        }
                      }}
                      helperText={
                        formik.touched.featured && formik.errors.featured
                          ? formik.errors.featured.toString()
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddPropertyType;
