import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Box, Card, Typography, Tabs, Tab } from "@mui/material";
import "react-phone-input-2/lib/bootstrap.css";

import ProfileSetup from "../../features/agents/ProfileSetup";
//
import {
  useEditUserMutation,
  useLazyGetSpecificUserByIdQuery,
} from "../../services/users";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { UploadMedia } from "../../utils/uploadMedia";
import { useFormik } from "formik";
import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddAgents = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [profile_picture, setProfilePicture] = useState("");
  const [value, setValue] = useState<number>(0);
  const [editUSERbyId] = useEditUserMutation();
  const [dialCode, setPhoneCode] = useState("+91");
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  const [fetchApi] = useLazyGetSpecificUserByIdQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const fetchDataById = async (id: any) => {
    setIsLoading(true);
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
        console.log("mohit", response?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: ByIdDetails?.name || "",
      email: ByIdDetails?.email || "",
      phoneNo: ByIdDetails?.phoneNo || "",
      profile_picture: ByIdDetails?.image || profile_picture,
      dialCode: dialCode,
      address: ByIdDetails?.address || "",
    },
    // enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Enter a valid email address"
        )
        .matches(
          /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/, // Regex to disallow emojis
          "Emojis are not allowed in the email address"
        ),
      name: Yup.string()
        .required("Full name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
      phoneNo: Yup.string()
        .required("Phone number is required")
        .min(5, "Phone number must be at least 5 characters")
        .max(16, "Phone number must be at most 16 characters"),
      address: Yup.string()
        .required("Address is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      formik.setSubmitting(true);
      let body = {
        name: values.name || "",
        email: values.email || "",
        phoneNo: values.phoneNo || "",
        image: values?.profile_picture || "",
        dialCode: dialCode,
        address: values?.address || "",
      };

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      try {
        setIsLoading(true);
        const response = await editUSERbyId({
          id,
          body: encryptedBody,
        }).unwrap();
        if (response?.statusCode === 200) {
          setIsLoading(false);
          successToast("User updated successfully");
          navigate("/manage-company");
        }
      } catch (error: any) {
        console.error(error);
        setIsLoading(false);
        errorToast(error?.data?.message);
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, [id]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <Card className="cards">
          {/* <Box className="custom_tabs"> */}
          {/* <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Bank Account Details" {...a11yProps(1)} />
                <Tab label="Documentation" {...a11yProps(1)} />
                <Tab label="Documentation" {...a11yProps(2)} />
                <Tab label="ID Proof" {...a11yProps(3)} />
              </Tabs>
            </Box> */}
          {/* <CustomTabPanel value={value} index={0}> */}
          <ProfileSetup />
          {/* </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Documentation />
            </CustomTabPanel> */}

          {/* </Box> */}
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddAgents;
