import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Modal,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ImageModal } from "../../components";
import Loader from "../../helpers/constants/Loader";
import { useLazyGetSpecificAgentByIdQuery } from "../../services/agents";
import moment from "moment";

const CompanyDocsDetails = () => {
    const location = useLocation();
    const { state } = location;
    const { _id } = useParams();
    const [ByIdDetails, setByIdDetails] = useState<any>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string>("");

    const [fetchApi, { isLoading }] = useLazyGetSpecificAgentByIdQuery();

    const fetchDataById = async (_id: any) => {
        try {
            const response = await fetchApi({ userId: _id }).unwrap();
            if (response.statusCode === 200) {
                setByIdDetails(response?.data?.documents || []);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataById(_id);
    }, [_id]);

    const handleOpenModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedImage("");
    };

    const getDocName = (num: number) => {
        switch (num) {
            case 1:
                return "Trade Licence";
            case 2:
                return "RERA Registration Documents";
            case 3:
                return "Brokrage Licence";
            case 4:
                return "Passport Copies";
            case 5:
                return "Power of Attorney documents";
            default:
                return "Invalid Document Number";
        }
    };


    return (
        <>
            <Card className="cards">
                <Loader isLoad={isLoading} />
                <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2} className="view_box">
                        <Grid item xs={10} className="view_box_list">
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <Typography component="h2" style={{ marginBottom: "-10px" }}>
                                            <Box sx={{ fontWeight: "bold", m: 0, fontSize: "larger" }}>Company Documents</Box>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    {ByIdDetails?.length ? ByIdDetails.map((item: any, index: number) => {
                                        return (
                                            item?.frontImage || item?.backImage ? (
                                                <Card style={{ marginBottom: "15px", padding: "15px" }}>
                                                    <div >
                                                        <p className="doc_heading">{item?.documentType ? "Document Name: " + getDocName(item?.documentType) : ""}</p>
                                                        {/* <p className="doc_details"> {item?.documentNumber ? "Document Number: " + item?.documentNumber : ""}</p>
                                                        <p className="doc_details">
                                                            {item?.expiryDate ? "Expiry Date: " + moment(item?.expiryDate).format("DD/MM/YYYY") : ""}
                                                        </p> */}
                                                    </div>
                                                    <Box className="docs_div">
                                                        {item?.frontImage ? (
                                                            <figure>
                                                                <img
                                                                    src={item?.frontImage || "/static/images/document.png"}
                                                                    alt="Front Document"
                                                                    style={{ cursor: "pointer", maxWidth: "300px" }}
                                                                />
                                                                <div className="overlay">
                                                                    <img
                                                                        onClick={() => handleOpenModal(item?.frontImage || "/static/images/documents.jpeg")}
                                                                        src='/static/images/visibility.png' alt='img'
                                                                    />
                                                                </div>
                                                            </figure>
                                                        ) : ("")}
                                                        {item?.backImage ? (
                                                            <figure>
                                                                <img
                                                                    src={item?.backImage || "/static/images/document.png"}
                                                                    alt="Front Document"
                                                                    style={{ cursor: "pointer", maxWidth: "300px" }}
                                                                />
                                                                <div className="overlay">
                                                                    <img
                                                                        onClick={() => handleOpenModal(item?.backImage || "/static/images/documents.jpeg")}
                                                                        src='/static/images/visibility.png' alt='img'
                                                                    />
                                                                </div>
                                                            </figure>
                                                        ) : ("")}
                                                    </Box>
                                                </Card>
                                            ) : null
                                        );
                                    }) : null}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <ImageModal open={openModal} handleClose={handleCloseModal} image={selectedImage} />
            </Card>

        </>
    );
};

export default CompanyDocsDetails;
