import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  NativeSelect,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import {
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  AreaChart,
  BarChart,
  Legend,
  Rectangle,
  Bar,
  Cell,
  Tooltip,
  CartesianGrid,
} from "recharts";

import { LineChart, PieChart } from "@mui/x-charts";
import { useNavigate, useParams } from "react-router-dom";
const AgentInsights = ({
  getAgentsInsight,
  agentData,
  superAgent,
}: {
  getAgentsInsight: any;
  agentData: any;
  superAgent?: boolean;
}) => {
  console.log("agentData :", agentData);
  const navigate = useNavigate();
  useEffect(() => {
    getAgentsInsight();
  }, []);
  const { _id: id } = useParams();
  return (
    <>
      <div className="page_heading main">
        <h3>Agent Insights</h3>
        {/* <p>Based on past 60 days</p> */}
      </div>
      <div className="agnts_mn">
        <div className="dashCard agnt_crds_1">
          <h4>{superAgent ? "Super" : "Total"} agents</h4>
          <h3 className="lrg_fnt">
            {(superAgent
              ? agentData?.agentsCount?.super
              : agentData?.agentsCount?.total) || 0}
          </h3>
          {!superAgent && (
            <p>
              <span
                className={
                  Number(agentData?.totalAgentsPercentage) < 0
                    ? "c_error"
                    : "c_success"
                }
              >
                {Number(agentData?.totalAgentsPercentage) < 0
                  ? `${agentData?.totalAgentsPercentage || 0}%`
                  : `+${agentData?.totalAgentsPercentage || 0}%`}
              </span>
            </p>
          )}
          {!superAgent && (
            <div className="flex_row">
              <div>
                <h4 className="brdr_ln">Super agents</h4>
                <h3>{agentData?.agentsCount?.super || 0}</h3>
              </div>
              <div>
                <h4 className="brdr_ln">Other agents</h4>
                <h3>
                  {agentData?.agentsCount?.total -
                    agentData?.agentsCount?.super -
                    agentData?.agentsCount?.verified || 0}
                </h3>
              </div>
              <div>
                <h4 className="brdr_ln"> Verified agents</h4>
                <h3>{agentData?.agentsCount?.verified || 0}</h3>
              </div>
            </div>
          )}
          {/* <Box component="a">How to become a SuperAgent →</Box> */}
        </div>
        <div className="dashCard agts_crd">
          <h4>Highest number of leads</h4>
          <ol className="agnt_insgts_lst">
            {Array.isArray(agentData?.leads) && agentData?.leads?.length ? (
              agentData?.leads?.map((data: any) => {
                return (
                  <li>
                    <LeaderboardIcon />
                    <div className="agnt_insgts">
                      <p>{data?.name || "No name"}</p>
                      <h6>{`${data?.leads + " leads"}`}</h6>
                    </div>
                  </li>
                );
              })
            ) : (
              <h3>No Leads</h3>
            )}

            <Button
              onClick={() =>
                navigate(
                  `/agent-insights?type=2${superAgent ? "&super=true" : ""}${id ? "&companyid=" + id : ""}`
                )
              }
            >
              View more
            </Button>
          </ol>
        </div>
        <div className="dashCard agts_crd">
          <h4>Highest number of Live listing</h4>
          <ol className="agnt_insgts_lst">
            {Array.isArray(agentData?.listing) && agentData?.listing?.length ? (
              agentData?.listing?.map((data: any) => {
                return (
                  <li>
                    <LeaderboardIcon />
                    <div className="agnt_insgts">
                      <p>{data?.name || "No name"}</p>
                      <h6>{`${data?.properties + " listings"}`}</h6>
                    </div>
                  </li>
                );
              })
            ) : (
              <h3>No Listings</h3>
            )}
            <Button
              onClick={() =>
                navigate(
                  `/agent-insights?type=1${superAgent ? "&super=true" : ""}${id ? "&companyid=" + id : ""}`
                )
              }
            >
              View more
            </Button>
          </ol>
        </div>
        <div className="dashCard agts_crd">
          <h4>Highest quality score</h4>
          <ol className="agnt_insgts_lst">
            {Array.isArray(agentData?.score) && agentData?.score?.length ? (
              agentData?.score?.map((data: any) => {
                return (
                  <li>
                    <LeaderboardIcon />
                    <div className="agnt_insgts">
                      <p>{data?.name || "No name"}</p>
                      <h6>{data?.percent ? `${data?.percent}%` : `0%`}</h6>
                    </div>
                  </li>
                );
              })
            ) : (
              <h3>No quality score</h3>
            )}
            <Button
              onClick={() =>
                navigate(
                  `/agent-insights?type=3${superAgent ? "&super=true" : ""}${id ? "&companyid=" + id : ""}`
                )
              }
            >
              View more
            </Button>
          </ol>
        </div>
      </div>
    </>
  );
};

export default AgentInsights;
