import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route
        path="/listing-performance/dashboard"
        element={<Pages.ListingPerformanceDashboard />}
      />
      <Route path="/agent-insights" element={<Pages.ManageRecipes />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manage-users" element={<Pages.ManageUsers />} />
      <Route path="/manage-users/details/:_id" element={<UserDetails />} />
      <Route path="/manage-users/usersform" element={<UsersForm />} />
      <Route path="/manage-users/usersform/:id" element={<UsersForm />} />
      <Route path="/manage-users/edit/chat" element={<Pages.ChatDetails />} />
      <Route
        path="/manage-users/token/details"
        element={<Pages.userTokenHistoryDetails />}
      />
      <Route
        path="/manage-users/property/details"
        element={<Pages.UserPropertyDetails />}
      />

      <Route
        path="/manage-users/property/details/UserChatInbox/:id"
        element={<Pages.UserChatInbox />}
      />

      <Route
        path="/manage-users/property/details/ChatInbox/:id"
        element={<Pages.PropertyChatInbox />}
      />
      <Route path="/analytics" element={<Pages.Analytics />} />

      <Route path="/cms" element={<Pages.ManageCms />} />

      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />

      <Route path="/manage-agents" element={<Pages.ManageRecipes />} />
      <Route path="/manage-agents/edit/:id" element={<Pages.AddAgents />} />
      <Route path="/manage-agents/add" element={<Pages.AddAgents />} />
      <Route
        path="/manage-agents/details/:_id"
        element={<Pages.AgentDetail />}
      />
      <Route path="/manage-agents/edit/id" element={<Pages.AddRecipes />} />
      <Route
        path="/manage-agents/edit/chat"
        element={<Pages.AgentChatDetails />}
      />
      <Route
        path="/manage-agent/token/details"
        element={<Pages.AgentTokenHistoryDetails />}
      />
      <Route
        path="/manage-agents/property-listing/details/:_id"
        element={<Pages.AgentNowPropertyDetails />}
      />

      <Route
        path="/manage-agents/property/details/AgentChatInbox/:id"
        element={<Pages.AgentChatInbox />}
      />
      <Route
        path="/manage-agents//:_id"
        element={<Pages.AgentNowPropertyDetails />}
      />
      <Route path="/manage-company" element={<Pages.ManageCompany />} />
      <Route path="/manage-company/add" element={<Pages.AddCompany />} />
      <Route
        path="/manage-company/add/:id/:tab"
        element={<Pages.AddCompany />}
      />
      <Route
        path="/manage-company/details/:_id"
        element={<Pages.CompanyDetails />}
      />
      <Route path="/manage-company/edit/:id" element={<Pages.AddCompany />} />
      <Route
        path="/company/property-listing/details/:_id"
        element={<Pages.CompanyPropertyDetails />}
      />

      <Route
        path="/manage-company/details/chatInbox/:id"
        element={<Pages.CompanyChatInbox />}
      />
      <Route path="/manage-ads" element={<Pages.ManageAds />} />
      <Route path="/manage-ads/add" element={<Pages.AddAds />} />
      <Route path="/manage-ads/edit/:_id" element={<Pages.AddAds />} />
      <Route path="/manage-ads/details/:_id" element={<Pages.AdsDetails />} />

      <Route path="/manage-banners" element={<Pages.ManageBanners />} />
      <Route path="/manage-banners/add" element={<Pages.AddBanners />} />
      <Route path="/manage-banners/edit/:_id" element={<Pages.AddBanners />} />
      <Route
        path="/manage-banners/details/:_id"
        element={<Pages.BannersDetails />}
      />

      <Route path="/property-listing" element={<Pages.ManageProperty />} />
      <Route path="/property-listing/add" element={<Pages.AddProperty />} />
      <Route
        path="/property-listing/edit/:_id"
        element={<Pages.AddProperty />}
      />
      <Route
        path="/property-listing/details/:_id"
        element={<Pages.PropertyDetails />}
      />

      <Route path="/manage-chat" element={<Pages.ManageChat />} />
      <Route
        path="/manage-chat/details/:id"
        element={<Pages.IndexChatDetails />}
      />

      <Route path="/manage-amenities" element={<Pages.ManageAmenities />} />
      <Route path="/manage-amenities/add" element={<Pages.AddAmenities />} />
      <Route
        path="/manage-amenities/edit/:_id"
        element={<Pages.AddAmenities />}
      />
      <Route
        path="/manage-amenities/details/:_id"
        element={<Pages.CategoryDetails />}
      />

      <Route path="/manage-reels" element={<Pages.ManageReels />} />
      <Route
        path="/manage-reels/details/:_id"
        element={<Pages.ReelsDetails />}
      />
      <Route
        path="/manage-reels/agents/:_id"
        element={<Pages.AgentReelById />}
      />
      <Route
        path="/manage-reels/company/:_id"
        element={<Pages.CompanyReelById />}
      />

      <Route
        path="/manage-propertyType"
        element={<Pages.ManagePropertyType />}
      />
      <Route
        path="/manage-propertyType/add"
        element={<Pages.AddPropertyType />}
      />
      <Route
        path="/manage-propertyType/edit/:_id"
        element={<Pages.AddPropertyType />}
      />

      <Route path="/manage-categories" element={<Pages.ManageCategory />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategory />} />
     
      <Route
        path="/manage-categories/edit/:_id"
        element={<Pages.AddCategory />}
      />
      <Route
        path="/manage-categories/details/:_id"
        element={<Pages.CategoryDetails />}
      />

      <Route path="/manage-revenue" element={<Pages.ManageRevenue />} />
      <Route path="/manage-insights" element={<Pages.ManageSuperDashboard />} />

      <Route path="/settings" element={<Pages.Settings />} />

      <Route
        path="/manage-propertyStatus"
        element={<Pages.ManagePropertyStatus />}
      />
      <Route
        path="/manage-propertyStatus/add"
        element={<Pages.AddPropertyStatus />}
      />
      <Route
        path="/manage-propertyStatus/edit/:_id"
        element={<Pages.AddPropertyStatus />}
      />

      {/* fasting */}

      <Route
        path="/manage-subscription"
        element={<Pages.ManageSubscription />}
      />
      <Route
        path="/manage-subscription/details/:_id"
        element={<Pages.SubscriptionDetails />}
      />
      <Route
        path="/manage-subscription/add"
        element={<Pages.Addsubscription />}
      />
      <Route
        path="/manage-subscription/edit/:_id"
        element={<Pages.Addsubscription />}
      />

      <Route
        path="/manage-company-subscription"
        element={<Pages.ManageCompanySubscription />}
      />
      <Route
        path="/manage-company-subscription/details"
        element={<Pages.CompanySubscriptionDetails />}
      />
      <Route
        path="/manage-company-subscription/add"
        element={<Pages.AddCompanySubscription />}
      />

      <Route path="/manage-boost" element={<Pages.ManageBoost />} />
      <Route
        path="/manage-boost/details/:_id"
        element={<Pages.BoostDetails />}
      />
      <Route path="/manage-boost/add" element={<Pages.AddBoost />} />
      <Route path="/manage-boost/edit/:id" element={<Pages.AddBoost />} />

      <Route path="/manage-emails" element={<Pages.ManageEmails />} />
      <Route path="/manage-listing" element={<Pages.ManageListing />} />

      <Route path="/manage-feedbacks" element={<Pages.ManageFeedbacks />} />
    </Routes>
  );
};

export default Routing;
