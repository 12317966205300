import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Card,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import ReplyIcon from '@mui/icons-material/Reply';
import { useNavigate, useParams } from "react-router-dom";
import { useLazyDeleteByIdAdQuery, useLazyGetAllAdsQuery } from "../../services/ads";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";

import Loader from "../../helpers/constants/Loader";
import RevertQueryModal from "../../components/RevertFeedbackModal";
import FeedbackDetails from "../../components/FeedbackDetails";
import { useLazyGetAllFeedbacksQuery } from "../../services/feedback";


const ManageFeedbacks = () => {
    const navigate = useNavigate();
    const [rows1, setRows] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [selectedId, setSelectedId] = useState<string>("");
    const onPageChange = (newPage: number) => {
        setPage(newPage);
        setRows([]);
    };
    const [msg, setMsg] = useState<string>("");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    let totalPages = Math.ceil(totalCount / limit);

    const [fetchFeedbacks, { isLoading }] = useLazyGetAllFeedbacksQuery();
    const fetchAllAddsApi = async () => {
        console.log(limit, "limit in fun");
        try {
            const response = await fetchFeedbacks({ page: page, limit: limit }).unwrap();
            setRows(response?.data?.feedback || []);
            setTotalCount(response?.data?.count || 0);
        }
        catch (e: any) {
            errorToast(e?.message);
        }
    }




    useEffect(() => {
        fetchAllAddsApi()
    }, [page, limit])

    return (
        <MainContainer>
            <Loader isLoad={isLoading} />


            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Manage Feedbacks</h1>
                </div>

                <Card className="cards">
                    {/* <Box className="cards_header">
                        <SearchBar2
                            value={searchTerm}
                            searchTerm={searchTerm}
                            onCross={() => setSearchTerm("")}
                            setDebouncedSearchTerm={setDebouncedSearchTerm}
                            onChange={(val: any) => {
                                if (isValidInput(val.target.value)) {
                                    setSearchTerm(val.target.value);
                                }
                            }}
                        />
                    </Box> */}
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>

                                <TableRow>
                                    <TableCell align="center">S.No</TableCell>
                                    <TableCell>User Name</TableCell>
                                    <TableCell>Phone no.</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows1?.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">
                                            No Feedbacks found
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    rows1?.map((row1: any, i: number) => (
                                        <TableRow key={row1?._id || i}>
                                            <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                                            <TableCell>{row1?.name || "-"}</TableCell>
                                            <TableCell>{row1?.dialCode + row1?.phoneNo || "-"}</TableCell>
                                            <TableCell>{row1?.email || "-"}</TableCell>
                                            <TableCell>
                                                {row1?.message
                                                    ? row1.message.split(" ").slice(0, 3).join(" ") + (row1.message.split(" ").length > 3 ? "..." : "")
                                                    : "-"}
                                            </TableCell>

                                            <TableCell>
                                                <Box className="table_actions">
                                                    <IconButton onClick={() => {
                                                        setOpen1(true);
                                                        setMsg(row1?.message)
                                                    }}>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setSelectedId(row1?._id);
                                                        }}
                                                    >
                                                        <ReplyIcon />
                                                    </IconButton>
                                                    {/* <IconButton
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setSelectedId(row1?._id);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton> */}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
            {rows1?.length > 0 ? (
                <Pagination
                    setPage={setPage}
                    module={rows1}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                    limit={limit}
                    setLimit={setLimit}
                />
            ) : (
                ""
            )}
            <RevertQueryModal
                open={open}
                setOpen={setOpen}
                id={selectedId}
            />
            <FeedbackDetails
                open={open1}
                setOpen={setOpen1}
                details={msg}
            />
        </MainContainer>
    );
};

export default ManageFeedbacks;
