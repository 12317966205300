import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Tabs,
  Tab,
  Grid,
  FormControl,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useLazyGetPendingCompanyQuery,
  useLazyGetRejectedAgentQuery,
} from "../../services/users";
import {
  useEditPropertyMutation,
  useLazyDeleteByIdPropertyQuery,
  useLazyGetApprovedPropertyQuery,
  useLazyGetPendingPropertyQuery,
  useLazyGetpropertYTypeListingQuery,
  useLazyGetRejectedPropertyQuery,
} from "../../services/property";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";
import AddIcon from "@mui/icons-material/Add";
import { useLazyExportPropertyCsvQuery } from "../../services/dashboard";
import { useLazyGetAllCategoryQuery } from "../../services/category";
import { Drafts } from "@mui/icons-material";

const ManageProperty = () => {
  const navigate = useNavigate();
  let role = 5;
  const { state } = useLocation();
  const [rows1, setRows1] = useState<any>([]);
  const [exportCsv] = useLazyExportPropertyCsvQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount1] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [fetchAllCategory] = useLazyGetAllCategoryQuery();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [propertyType, setPropertyType] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [price, setPrice] = useState<string>("");

  const [properties, setProperties] = useState<{ _id: string; name: string }[]>(
    []
  );
  const [propertyTypeList] = useLazyGetpropertYTypeListingQuery();
  const [categories, setCategories] = useState<{ _id: string; name: string }[]>(
    []
  );
  const onPageChange1 = (newPage: number) => {
    setPage(newPage);
    setRows1([]);
  };
  const [getPendingProperty, { isLoading }] = useLazyGetPendingPropertyQuery();
  const [deletePropertyById] = useLazyDeleteByIdPropertyQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const fetchCategories = async () => {
    try {
      const response = await fetchAllCategory({
        limit: 50,
        page: 1,
        search: "",
        type: "subcategory",
        parentId: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      }
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchPropertyType = async () => {
    try {
      const response = await propertyTypeList({}).unwrap();
      if (response?.statusCode === 200) {
        setProperties(response?.data?.propertyType || []);
      }
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchPendingData = async (page: number, search: string) => {
    try {
      const response = await getPendingProperty({
        page: page,
        search: search,
        limit: limit,
        propertyType: propertyType,
        category: category,
        price: price,
        agentId: state?.agentId ? state?.agentId : "",
        propertyName: state?.type ? state?.type : null,
        isDraftSaved: Boolean(searchParams?.get("draft")),
      }).unwrap();
      if (response.statusCode === 200) {
        setRows1(response?.data?.property || []);
        setTotalCount1(response?.data?.count);
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const handleDeleteProperty = async (userId: any) => {
    try {
      const response = await deletePropertyById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Property deleted successfully");
        fetchPendingData(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({}).unwrap();
      console.log("eugded", res);

      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };

  useEffect(() => {
    fetchPendingData(page, debouncedSearchTerm);
  }, [
    role,
    page,
    debouncedSearchTerm,
    limit,
    propertyType,
    category,
    price,
    searchParams,
  ]);

  useEffect(() => {
    fetchCategories();
    fetchPropertyType();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            Manage Property Listing{" "}
            {Boolean(searchParams.get("draft")) ? `(Drafts)` : ""}
          </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />

              <Box className="cards_header_right">
                {Boolean(searchParams.get("draft")) ? (
                  ""
                ) : (
                  <Button
                    className="btn btn_primary"
                    onClick={() => {
                      setSearchParams("draft=true");
                    }}
                  >
                    <Drafts />
                    Drafts
                  </Button>
                )}
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/property-listing/add")}
                >
                  <AddIcon />
                  Add Property
                </Button>
                <Button className="btn btn_primary" onClick={handleExportCsv}>
                  <FileDownloadIcon /> Export CSV
                </Button>
              </Box>
            </Box>
            <Box>
              <div className="Filters_div">
                <div className="childFilter">
                  <Typography className="custom_label">
                    Property Type
                  </Typography>
                  <Select
                    className="childFilter"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {properties?.length > 0
                      ? properties?.map((it: any) => (
                          <MenuItem key={it?._id} value={it?._id}>
                            {it?.name}
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                </div>
                <div className="childFilter">
                  <Typography className="custom_label">Categories</Typography>
                  <Select
                    className="childFilter"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {categories?.length > 0
                      ? categories?.map((it: any) => (
                          <MenuItem key={it?._id} value={it?._id}>
                            {it?.name}
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                </div>
                <div className="childFilter">
                  <Typography className="custom_label">Price</Typography>
                  <Select
                    className="childFilter"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>

                    <MenuItem value="3">Low to high </MenuItem>
                    <MenuItem value="4">High to low </MenuItem>
                  </Select>
                </div>
                <Button
                  className="btn btn_primary"
                  style={{ backgroundColor: "black", marginTop: "15px" }}
                  onClick={() => {
                    setPropertyType("");
                    setCategory("");
                    setPrice("");
                  }}
                >
                  Clear filters
                </Button>
              </div>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Property Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Owner Name</TableCell>
                    <TableCell>Property Category</TableCell>
                    <TableCell>Property Type</TableCell>
                    {/* <TableCell>Request</TableCell> */}

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows1.length == 0 ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        No property found
                      </TableCell>
                    </TableRow>
                  ) : (
                    rows1.map((row: any, i: any) => (
                      <TableRow key={row?.i}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          <figure className="user_img">
                            <img
                              style={{ borderRadius: 0 }}
                              src={
                                row?.images[0] || `/static/images/product1.png`
                              }
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell>{row?.name || "-"}</TableCell>
                        <TableCell>{row?.price || "-"}</TableCell>
                        <TableCell>{row?.users?.name || "-"}</TableCell>
                        <TableCell>{row?.category?.name || "-"}</TableCell>
                        <TableCell>{row?.propertyTypes?.name || "-"}</TableCell>
                        {/* <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={receiver}
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) => {
                              handleReceiverChange(value, row?._id);
                            }}
                          
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="1">Pending</MenuItem>
                            <MenuItem value="2">Approve</MenuItem>
                            <MenuItem value="3">Reject</MenuItem>
                          </Select>
                        </TableCell> */}

                        <TableCell>
                          <Box className="table_actions">
                            {!Boolean(searchParams.get("draft")) && (
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/property-listing/details/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            )}
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/property-listing/edit/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`
                                )
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {rows1?.length > 0 ? (
              <Pagination
                setPage={setPage}
                module={rows1}
                page={page}
                onPageChange={onPageChange1}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
              />
            ) : (
              ""
            )}
            <WarnModal
              open={open}
              setOpen={setOpen}
              handleDelete={() => handleDeleteProperty(selectedId)}
              name="Property"
            />
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageProperty;
