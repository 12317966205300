import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import ClearIcon from '@mui/icons-material/Clear';
import { useFormik } from "formik";
import { useAddUserApiMutation, useEditUserMutation } from "../../services/users";
import { UploadMedia } from "../../utils/uploadMedia";
import { errorToast, successToast } from "../../helpers/toast";
import { useLazyGetSpecificAgentByIdQuery } from "../../services/agents";
import { CommonBody, Document } from "../../types/General";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../helpers/constants/Loader";

type docs = {
  documentType?: number;
  documentNumber: string;
  expiryDate: string;
  backImage: string;
  frontImage: string;
}

const CompanyDocumentation = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ByIdDetails, setByIdDetails] = useState<docs[]>();
  const [editUSERbyId] = useEditUserMutation();
  const [addDocs] = useAddUserApiMutation();
  const [fetchApi] = useLazyGetSpecificAgentByIdQuery();

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {

    const files = event.target.files;
    const file = files?.length ? files[0] : null;
    if (!file) {
      return;
    }
    try {
      setIsLoading(true)
      const res = await UploadMedia(file);
      setIsLoading(false)
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        formik.setFieldValue(type, imageUrl);

        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };

  const fetchDataById = async (id: any) => {
    setIsLoading(true)
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setIsLoading(false)
        setByIdDetails(response?.data?.documents || {});
        console.log(response?.data?.documents, "response?.data?.documents");

        // formik.setValues({
        //   documentName: response?.data?.documents[0]?.name || "",
        //   expiryDate: moment(response?.data?.documents[0]?.expiryDate).format("YYYY-MM-DD") || "",
        //   frontImage: response?.data?.documents[0]?.frontImage || "",
        //   backImage: response?.data?.documents[0]?.backImage || "",
        // });
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error);
    }
  };


  const formik = useFormik({
    initialValues: {
      documentType_1: "",
      documentNumber_1: "",
      expiryDate_1: "",
      frontImage_1: "",
      backImage_1: "",

      documentType_2: "",
      documentNumber_2: "",
      expiryDate_2: "",
      frontImage_2: "",
      backImage_2: "",

      documentType_3: "",
      documentNumber_3: "",
      expiryDate_3: "",
      frontImage_3: "",
      backImage_3: "",

      documentType_4: "",
      documentNumber_4: "",
      expiryDate_4: "",
      frontImage_4: "",
      backImage_4: "",

      documentType_5: "",
      documentNumber_5: "",
      expiryDate_5: "",
      frontImage_5: "",
      backImage_5: "",
    },
    validationSchema: Yup.object().shape({
      // documentNumber_1: Yup.string().required("Licence number is required"),
      // expiryDate_1: Yup.date().required("Expiry date is required").nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!formik.isValid) return;

      setSubmitting(true);

      let body: any = {
        documents: [
          {
            documentType: 1,
            // documentNumber: values.documentNumber_1,
            // expiryDate: values.expiryDate_1 ? moment(values.expiryDate_1)
            //   .utc()
            //   .format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
            frontImage: formik.values.frontImage_1,
            backImage: formik.values.backImage_1,
          },
          {
            documentType: 2,
            // documentNumber: values.documentNumber_2,
            // expiryDate: values.expiryDate_2 ? moment(values.expiryDate_2)
            //   .utc()
            //   .format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
            frontImage: formik.values.frontImage_2,
            backImage: formik.values.backImage_2,
          },
          {
            documentType: 3,
            // documentNumber: values.documentNumber_3,
            // expiryDate: values.expiryDate_3 ? moment(values.expiryDate_3)
            //   .utc()
            //   .format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
            frontImage: formik.values.frontImage_3,
            backImage: formik.values.backImage_3,
          },
          {
            documentType: 4,
            // documentNumber: values.documentNumber_4,
            // expiryDate: values.expiryDate_4 ? moment(values.expiryDate_4)
            //   .utc()
            //   .format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
            frontImage: formik.values.frontImage_4,
            backImage: formik.values.backImage_4,
          },
          {
            documentType: 5,
            // documentNumber: values.documentNumber_5,
            // expiryDate: values.expiryDate_5 ? moment(values.expiryDate_5)
            //   .utc()
            //   .format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
            frontImage: formik.values.frontImage_5,
            backImage: formik.values.backImage_5,
          }
        ]
      };
      if (!id) {
        body = { ...body, role: 5 }
      }

      console.log("profile_body====", body);
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      if (id) {
        try {
          setIsLoading(true)
          const response = await editUSERbyId({
            id,
            body: encryptedBody,
          }).unwrap();
          if (response.statusCode === 200) {
            setIsLoading(false)
            successToast("Company updated successfully");
            navigate("/manage-company");
          } else {
            errorToast("Failed to update user");
          }
        } catch (error) {
          setIsLoading(false)
          console.error("Error updating user:", error);
          errorToast("Error updating user. Please try again.");
        } finally {
          setIsLoading(false)
          setSubmitting(false);
        }
      } else {
        try {
          setIsLoading(true)
          const response = await addDocs({
            body: encryptedBody,
          }).unwrap();
          if (response.statusCode === 200) {
            setIsLoading(false)
            successToast("Company Documents added successfully");
            navigate("/manage-company");
          } else {
            errorToast("Failed to update company");
          }
        } catch (error) {
          setIsLoading(false)
          console.error("Error updating user:", error);
          errorToast("Error updating user. Please try again.");
        } finally {
          setIsLoading(false)
          setSubmitting(false);
        }
      }

    },
  });


  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, []);


  useEffect(() => {
    if (ByIdDetails && ByIdDetails.length) {
      ByIdDetails.forEach(item => {
        const documentType:number = item?.documentType ? item?.documentType : 0;
        if (documentType >= 1 && documentType <= 5) {
          formik.setFieldValue(`documentNumber_${documentType}`, item?.documentNumber);
          formik.setFieldValue(`frontImage_${documentType}`, item?.frontImage);
          formik.setFieldValue(`backImage_${documentType}`, item?.backImage);
          formik.setFieldValue(`expiryDate_${documentType}`,item?.expiryDate ?  moment(item?.expiryDate).format("YYYY-MM-DD"):"" );
        }
      });
    }
  }, [ByIdDetails]);




  return (
    <div className="main_layout">
      <Loader isLoad={isLoading} />
      <div className="dashboard">
        <h1 className="main_heading"> Documentation</h1>

        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-company");
          }}
        >
          Back
        </Button>
      </div>
      {/* <Card className="card"> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="single_doc">
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading">
              <Typography className="document_head" variant="h5">
                Trade Licence
              </Typography>
            </Grid>
            {/* <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Licence Number</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="documentNumber_1"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Licence Number"
                  value={formik.values.documentNumber_1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.documentNumber_1 && Boolean(formik.errors.documentNumber_1)}
                  helperText={
                    formik.touched.documentNumber_1 &&
                    formik.errors.documentNumber_1
                  }
                />
              </div>
              <div className="control_group">
                <Typography className="custom_label">Expiry Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="expiryDate_1"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Expiry Date"
                  value={formik.values.expiryDate_1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.expiryDate_1 && Boolean(formik.errors.expiryDate_1)}
                  helperText={
                    formik.touched.expiryDate_1 &&
                    formik.errors.expiryDate_1
                  }
                />
              </div>
            </Grid> */}

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Licence Front</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.frontImage_1  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-front-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "frontImage_1")}
                    />
                    <span onClick={() => formik.setFieldValue('frontImage_1', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Licence Back</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.backImage_1  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-back-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "backImage_1")}
                    />
                    <span onClick={() => formik.setFieldValue('backImage_1', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

          </Grid>
        </div>
        <div className="single_doc">
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading">
              <Typography className="document_head" variant="h5">
                RERA Registration Docs
              </Typography>
            </Grid>
            {/* <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Document Number</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="documentNumber_2"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Document Number"
                  value={formik.values.documentNumber_2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.documentNumber_2 && Boolean(formik.errors.documentNumber_2)}
                  helperText={
                    formik.touched.documentNumber_2 &&
                    formik.errors.documentNumber_2
                  }
                />
              </div>
              <div className="control_group">
                <Typography className="custom_label">Expiry Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="expiryDate_2"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Expiry Date"
                  value={formik.values.expiryDate_2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.expiryDate_2 && Boolean(formik.errors.expiryDate_2)}
                  helperText={
                    formik.touched.expiryDate_2 &&
                    formik.errors.expiryDate_2
                  }
                />
              </div>
            </Grid> */}

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Document Image</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.frontImage_2  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-front-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "frontImage_2")}
                    />
                    <span onClick={() => formik.setFieldValue('frontImage_2', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Document Image</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.backImage_2  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-back-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "backImage_2")}
                    />
                    <span onClick={() => formik.setFieldValue('backImage_2', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

          </Grid>
        </div>
        <div className="single_doc">
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading">
              <Typography className="document_head" variant="h5">
                Brokerage License
              </Typography>
            </Grid>
            {/* <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">License Number</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="documentNumber_3"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter License Number"
                  value={formik.values.documentNumber_3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.documentNumber_3 && Boolean(formik.errors.documentNumber_3)}
                  helperText={
                    formik.touched.documentNumber_3 &&
                    formik.errors.documentNumber_3
                  }
                />
              </div>
              <div className="control_group">
                <Typography className="custom_label">Expiry Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="expiryDate_3"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Expiry Date"
                  value={formik.values.expiryDate_3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.expiryDate_3 && Boolean(formik.errors.expiryDate_3)}
                  helperText={
                    formik.touched.expiryDate_3 &&
                    formik.errors.expiryDate_3
                  }
                />
              </div>
            </Grid> */}

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">License Front</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.frontImage_3  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-front-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "frontImage_3")}
                    />
                    <span onClick={() => formik.setFieldValue('frontImage_3', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">License Back</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.backImage_3  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-back-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "backImage_3")}
                    />
                    <span onClick={() => formik.setFieldValue('backImage_3', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

          </Grid>
        </div>
        <div className="single_doc">
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading">
              <Typography className="document_head" variant="h5">
                Passport Copies of Key Personnel
              </Typography>
            </Grid>
            {/* <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Passport Number</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="documentNumber_4"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Passport Number"
                  value={formik.values.documentNumber_4}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.documentNumber_4 && Boolean(formik.errors.documentNumber_4)}
                  helperText={
                    formik.touched.documentNumber_4 &&
                    formik.errors.documentNumber_4
                  }
                />
              </div>
              <div className="control_group">
                <Typography className="custom_label">Expiry Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="expiryDate_4"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Expiry Date"
                  value={formik.values.expiryDate_4}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.expiryDate_4 && Boolean(formik.errors.expiryDate_4)}
                  helperText={
                    formik.touched.expiryDate_4 &&
                    formik.errors.expiryDate_4
                  }
                />
              </div>
            </Grid> */}

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Image</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.frontImage_4  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-front-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "frontImage_4")}
                    />
                    <span onClick={() => formik.setFieldValue('frontImage_4', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Image</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.backImage_4  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-back-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "backImage_4")}
                    />
                    <span onClick={() => formik.setFieldValue('backImage_4', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

          </Grid>
        </div>
        <div className="single_doc">
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading">
              <Typography className="document_head" variant="h5">
                Power of Attorney documents
              </Typography>
            </Grid>
            {/* <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Document Number</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="documentNumber_5"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Document Number"
                  value={formik.values.documentNumber_5}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.documentNumber_5 && Boolean(formik.errors.documentNumber_5)}
                  helperText={
                    formik.touched.documentNumber_5 &&
                    formik.errors.documentNumber_5
                  }
                />
              </div>
              <div className="control_group">
                <Typography className="custom_label">Expiry Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="expiryDate_5"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Expiry Date"
                  value={formik.values.expiryDate_5}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.expiryDate_5 && Boolean(formik.errors.expiryDate_5)}
                  helperText={
                    formik.touched.expiryDate_5 &&
                    formik.errors.expiryDate_5
                  }
                />
              </div>
            </Grid> */}

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Document Image</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.frontImage_5  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-front-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "frontImage_5")}
                    />
                    <span onClick={() => formik.setFieldValue('frontImage_5', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="control_group">
                <Typography className="custom_label">Document Image</Typography>
                <Box className="docs_div v2">
                  <figure>
                    <img
                      src={formik.values.backImage_5  || "/static/images/upload.png"}
                      alt=""
                    />
                    <input
                      type="file"
                      id="upload-back-image"
                      style={{ display: "none" }}
                      onChange={(event) => handleImageUpload(event, "backImage_5")}
                    />
                    <span onClick={() => formik.setFieldValue('backImage_5', "")} className="clear_icon"><ClearIcon /></span>
                  </figure>
                </Box>
              </div>
            </Grid>

          </Grid>
        </div>

        <div className="form_btn">
          <Button
            size="large"
            type="submit"
            className="btn btn_primary"
          >
            Submit
          </Button>
        </div>
      </form>
      {/* </Card> */}
    </div>
  );
};

export default CompanyDocumentation;
