import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Button,
  Card,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetAllCategoryQuery,
  useEditCategoryMutation,
  useLazyDeleteByIdCategoryQuery,
} from "../../services/category";
import { errorToast, successToast } from "../../helpers/toast";

import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
const ManageCategory = () => {
  const navigate = useNavigate();
  const [categoryStatus] = useEditCategoryMutation();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [categories, setCategories] = useState<string[]>([])
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [rows, setRows] = useState<any>([]);
  const [filter, setFilter] = useState<string>("");
  const [fetchAllCategory, { isLoading }] = useLazyGetAllCategoryQuery();
  const [deleteCategory] = useLazyDeleteByIdCategoryQuery();


  const CategoryList = async (page: number, search: string) => {
    try {
      const response = await fetchAllCategory({ page: page, search: search, limit: limit, type: "subcategory", parentId: filter }).unwrap();
      setRows(response?.data?.category || []);
      setTotalCount(response?.data?.count);
    }
    catch (e: any) {
      errorToast(e?.data?.message);
    }
  }

  const filterCategoryList = async () => {
    try {
      const response = await fetchAllCategory({ page: 1, search: "", limit: 20, type: "category", parentId: "" }).unwrap();
      setCategories(response?.data?.category || []);
    }
    catch (e: any) {
      errorToast(e?.data?.message);
    }
  }
  const handleDeleteCategory = async (userId: any) => {
    try {
      const response = await deleteCategory({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Category deleted successfully");
        CategoryList(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };


  useEffect(() => {
    CategoryList(page, debouncedSearchTerm)

  }, [page, debouncedSearchTerm, limit, filter])

  useEffect(() => {
    filterCategoryList()
  }, [])

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Category</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
          <div className="cards_header_right">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box>
              <div className="Filters_div" >

                <div className="childFilter">
                  <Select
                    className="childFilter"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Category
                    </MenuItem>
                    {categories?.length > 0
                      ? categories?.map((it: any) => (
                        <MenuItem key={it?._id} value={it?._id}>
                          {it?.name}
                        </MenuItem>
                      ))
                      : ""}
                  </Select>
                </div>

                <Button className="btn btn_primary"
                  style={{ backgroundColor: "black", marginTop: "15px" }}
                  onClick={() => { setFilter(""); }}>
                  Clear filter
                </Button>
              </div>
            </Box>
            </div>



              <Box className="cards_header_right">
                {/* <Button className="btn btn_primary">
                <FileDownloadIcon /> Export CSV
              </Button> */}
                <Button
                  className="btn btn_primary"
                  onClick={() => {
                    navigate("/manage-categories/add");
                  }}
                >
                  <AddIcon />
                  Add Sub-category
                </Button>
              </Box>

          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Sub-category Name</TableCell>
                  <TableCell>Category </TableCell>
                  <TableCell>Tokens</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length == "0" ? (<TableRow>
                  <TableCell colSpan={12} align="center">
                    No Sub-category found
                  </TableCell>
                </TableRow>) :
                  (rows.map((row: any, index: any) => (
                    <TableRow key={row._id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>
                        <figure className="user_img">
                          <img
                            style={{ borderRadius: 0 }}
                            src={row?.image ? row?.image : `row.image`}
                            alt={row?.name || "-"}
                          />
                        </figure>
                      </TableCell>
                      <TableCell>{row?.name || "-"}</TableCell>
                      <TableCell>{row?.parentId?.name ||  "-"}</TableCell>
                      <TableCell>{row?.settings?.token || "-"}</TableCell>

                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-categories/details/${row?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-categories/edit/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteCategory(selectedId)}
        name="Sub-category"
      />
    </MainContainer>
  );
};

export default ManageCategory;
