import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MouseIcon from "@mui/icons-material/Mouse";
import {
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";

const CompanyInsights = ({
  getOverallInsight,
  graphData,
  showRanks,
  barRankData,
}: {
  getOverallInsight: any;
  graphData: any;
  showRanks?: boolean;
  barRankData?: any;
}) => {
  useEffect(() => {
    getOverallInsight();
  }, []);
  console.log("barRankData :", barRankData);
  console.log(graphData, "graphData");

  const MetricCircle = ({ title, total, data }: any) => {
    const colors = ["#E0E7FF", "#818CF8", "#4338CA"];

    return (
      <>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Box className="chart_container">
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx={95}
              cy={95}
              innerRadius={60}
              outerRadius={80}
              paddingAngle={2}
              dataKey="value"
            >
              {data.map((entry: any, index: number) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
          </PieChart>
          <Box className="chart_total">
            <h3>{total?.toString()}</h3>
            <p>Total</p>
          </Box>
        </Box>
        <div className="legend">
          {data.map((entry: any, index: number) => (
            <p key={`legend-${index}`} className="legend-item">
              <span
                className="legend-color"
                style={{ backgroundColor: colors[index] }}
              ></span>
              {entry.name}
              <span>
                {entry.value?.toString()} ({entry.percentage?.toFixed(2)}%)
              </span>
            </p>
          ))}
        </div>
      </>
    );
  };

  const MetricCircleDummy = ({ title, data }: any) => {
    const radius = 80;
    const circumference = 2 * Math.PI * radius;
    const colors = ["#E0E7FF", "#818CF8", "#4338CA"];

    return (
      <>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Box className="chart_container" display="flex" justifyContent="center" alignItems="center">
          <svg width={200} height={200}>
            <circle
              cx="100"
              cy="100"
              r={radius}
              stroke="#D3D3D3"
              strokeWidth={20}
              fill="none"
            />
          </svg>
        </Box>

        <div className="legend">
          {data.map((entry: any, index: number) => (
            <p key={`legend-${index}`} className="legend-item">
              <span
                className="legend-color"
                style={{ backgroundColor: colors[index] }}
              ></span>
              {entry.name}
              <span>
                {entry.value?.toString()} ({entry.percentage?.toFixed(2)}%)
              </span>
            </p>
          ))}
        </div>
      </>
    );
  };


  const RankBox = ({ icon, rank, action }: any) => (
    <div className="rank_box">
      {icon}
      <p>Your rank is {rank}</p>
      {/* <Box component="a">{action}</Box> */}
    </div>
  );


  return (
    <>
      <div className="dashboardCompany">
        <div className="page_heading main">
          <h3>Overall performance</h3>
        </div>
        <div className="overall_grid">
          <div className="overall_item bx_shdw">
            {graphData?.listingsDataTotal === 0 ? (
              <MetricCircleDummy
                title="Listings"
                data={graphData?.listingsData}

              />
            ) : (
              <MetricCircle
                title="Listings"
                total={graphData?.listingsDataTotal}
                data={graphData?.listingsData}
              />
            )}

            {showRanks && (
              <RankBox
                icon={<PersonIcon />}
                rank={barRankData?.rank?.listing}
                action="Add listings"
              />
            )}
          </div>
          <div className="overall_item bx_shdw">
            {graphData?.impressionsDataTotal === 0 ? (
              <MetricCircleDummy
                title="Impressions"
                data={graphData?.impressionsData}
              />
            ) : (
              <MetricCircle
                title="Impressions"
                total={graphData?.impressionsDataTotal}
                data={graphData?.impressionsData}
              />
            )}

            {showRanks && (
              <RankBox
                icon={<VisibilityIcon />}
                rank={barRankData?.rank?.impression}
                action="Boost your listings"
              />
            )}
          </div>
          <div className="overall_item bx_shdw">
            {graphData?.clicksDataTotal === 0 ? (
              <MetricCircleDummy
                title="Clicks"
                data={graphData?.clicksData}
              />
            ) : (
              <MetricCircle
                title="Clicks"
                total={graphData?.clicksDataTotal}
                data={graphData?.clicksData}
              />
            )}

            {showRanks && (
              <RankBox
                icon={<MouseIcon />}
                rank={barRankData?.rank?.click}
                action="Raise your quality score"
              />
            )}
          </div>
        </div>
      </div>

      {showRanks && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          {/* First BarChart */}
          <div style={{ width: "calc(50% - 10px)" }} className="bx_shdw">
            <div style={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={[
                    {
                      userProperties:
                        barRankData?.bars?.userProperties?.toFixed(2),
                      averageProperties:
                        barRankData?.bars?.averageProperties?.toFixed(2),
                    },
                  ]}
                  barGap={5}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    domain={[
                      0,
                      Math.ceil(
                        Math.max(
                          barRankData?.bars?.userProperties?.toFixed(2),
                          barRankData?.bars?.averageProperties?.toFixed(2)
                        ) + 10
                      ),
                    ]}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="averageProperties"
                    fill="#4B2E83"
                    name="Average properties"
                  />
                  <Bar
                    dataKey="userProperties"
                    fill="#B8A0FF"
                    name="User properties"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <p style={{ textAlign: "center", margin: "0px" }}>Properties →</p>
          </div>

          {/* Second BarChart */}
          <div style={{ width: "calc(50% - 10px)" }} className="bx_shdw">
            <div style={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={[
                    {
                      userScore: barRankData?.bars?.userScore?.toFixed(2),
                      averageScores:
                        barRankData?.bars?.averageScores?.toFixed(2),
                    },
                  ]}
                  barGap={5}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    domain={[
                      0,
                      Math.ceil(
                        Math.max(
                          barRankData?.bars?.userScore?.toFixed(2),
                          barRankData?.bars?.averageScores?.toFixed(2)
                        ) + 10
                      ),
                    ]}
                  />
                  <Tooltip />
                  <Bar dataKey="userScore" fill="#4B2E83" name="User scores" />
                  <Bar
                    dataKey="averageScores"
                    fill="#B8A0FF"
                    name="Average scores"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <p style={{ textAlign: "center", margin: "0px" }}>Scores →</p>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyInsights;
