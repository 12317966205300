import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import * as Yup from "yup";
import { Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useEditAdsMutation, useLazyGetSpecificAdByIdQuery, usePostAdvertisementMutation } from "../../services/ads";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { errorToast, successToast } from "../../helpers/toast";
import { CommonBody } from "../../types/General";
import { useLazyGetAllCategoryQuery } from "../../services/category";
import { useLazyGetAllAgentQuery } from "../../services/agents";
import { isNumber } from "../../utils/validation";
import { UploadMedia } from "../../utils/uploadMedia";
import { useLazyGetApprovedAgentQuery } from "../../services/users";

const AddAds = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [autocomplete, setAutocomplete] = useState<any>();
  const [categories, setCategories] = useState<{ _id: string, name: string }[]>([]);
  const [agents, setAgents] = useState<{ _id: string, name: string }[]>([]);
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  const [imageError, setImageError] = useState("");
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [fetchApi] = useLazyGetSpecificAdByIdQuery();
  const [fetchAllCategory] = useLazyGetAllCategoryQuery();
  const [agentList] = useLazyGetApprovedAgentQuery();
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        formik.setFieldValue("address", place?.formatted_address);
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
      }
    }
  };
  const handleCategoryChange = (event: SelectChangeEvent) => {
    formik.setFieldValue("category", event.target.value);
  };

  const [addAdvertisement, { isLoading }] = usePostAdvertisementMutation();
  const [updateAdvertisement] = useEditAdsMutation();
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        setImageError("Image is required");
        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        setUploadedImages((prev) => [...prev, imageUrl]);
        setImageError("");
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const fetchCategories = async () => {
  //   try {
  //     const response = await fetchAllCategory({
  //       limit: 50,
  //       page: 1,
  //       search: "",
  //       type:"category"
  //     }).unwrap();
  //     const filtereCategories = response?.data?.category.filter(
  //       (it: { name: string }) => it?.name !== ""
  //     );
  //     setCategories(filtereCategories || []);

  //   } catch (e: any) {
  //     errorToast(e?.message);
  //   }
  // };

  const fetchAgentList = async () => {
    try {
      const response = await agentList({
        limit: 50,
        page: 1,
        search: ""
      }).unwrap();
      if (response.statusCode === 200) {
        const filteredAgents = response?.data?.user.filter(
          (it: { name: string }) => it?.name !== ""
        );
        setAgents(filteredAgents || []);

      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",

      address: "",
      startDate: "",
      endDate: "",
      link: ""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Property Name is required"),

      address: Yup.string().required("Address is required"),
      startDate: Yup.date()
        .required("Start Date is required")
        .test("startDate", "Start date can't be after end date", function (value) {
          const { endDate } = this.parent;
          return !endDate || value <= endDate;
        }),
      endDate: Yup.date()
        .required("End Date is required")
        .min(Yup.ref("startDate"), "End Date can't be before Start Date"),

      link: Yup.string().required("Link is required"),
    }),
    onSubmit: async (values) => {

      formik.setSubmitting(true);
      if (uploadedImages?.length === 0) {
        errorToast("Image is required");
        return;
      }
      if (!_id) {
        try {
          const body = {
            title: values.title,

            address: values.address,
            startDate: values.startDate,
            endDate: values.endDate,
            link: values.link,
            images: uploadedImages,
          };
          console.log("link", body);
          const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

          const response = await addAdvertisement(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Ads Added Successfully.");
            navigate("/manage-ads");
          }
        } catch (error: any) {
          errorToast(error?.data?.message);
        } finally {
          formik.setSubmitting(false);
        }
      } else {
        try {
          const body = {
            title: values.title,

            address: values.address,
            startDate: values.startDate,
            endDate: values.endDate,
            link: values.link,
            images: uploadedImages,
          };
          console.log("link", body);
          const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

          const response = await updateAdvertisement({ id: _id, body: encryptedBody }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Ads Updated Successfully.");
            navigate("/manage-ads");
          }
        } catch (error: any) {
          errorToast(error?.data?.message);
        } finally {
          formik.setSubmitting(false);
        }
      }
    },
  });

  console.log(uploadedImages, "uploadedImages array");

  

  useEffect(() => {
    fetchAgentList();
  }, []);

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        title: ByIdDetails.title || "",

        address: ByIdDetails.address || "",
        startDate: ByIdDetails.startDate ? new Date(ByIdDetails.startDate).toISOString().split('T')[0] : "",
        endDate: ByIdDetails.endDate ? new Date(ByIdDetails.endDate).toISOString().split('T')[0] : "",
        link: ByIdDetails?.link || ""
      });
      if (ByIdDetails.images) {
        setUploadedImages(ByIdDetails.images);
      }
    }
  }, [ByIdDetails]);


  useEffect(() => {
    if (_id)
      fetchDataById(_id)
  }, [_id]);

  const removeImage = (index: number) => {
    setUploadedImages((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{_id ? `Edit Ads` : `Add Ads`}</h1>
          <Button className="btn btn_primary" onClick={() => navigate("/manage-ads")}>
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">Images</Typography>
                  <Box className="upload_images_container">
                    {uploadedImages?.map((image, index) => (
                      <div key={index} className="upload_image_preview">
                        <CardMedia
                          component="img"
                          style={{ borderRadius: 0 }}
                          image={image}
                          alt={`uploaded_image_${index}`}
                        />
                        <CancelIcon
                          onClick={(e) => {
                            e.preventDefault();
                            removeImage(index);
                          }}
                        />
                      </div>
                    ))}
                    {uploadedImages.length < 20 && (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              style={{ borderRadius: 0 }}
                              src="/static/images/product1.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                        {imageError && (
                          <Typography color="error" variant="caption">
                            {imageError}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid>
                {/* ... your other form fields here ... */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Property Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Property Name"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>

                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Address</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="address"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid> */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <div className="control_group w_50">
                    <Typography className="custom_label">Address</Typography>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                      options={{
                        types: ["political"],
                        componentRestrictions: { country: "ae" },
                    }}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        name="address"
                        value={formik.values?.address}
                        placeholder="Enter Address"
                        fullWidth
                        helperText={
                          formik.touched.address &&
                            typeof formik.errors.address === "string"
                            ? formik.errors.address
                            : undefined
                        }
                        onChange={(e) => {
                          if (
                            e.target.value.trim() === "" ||
                            e.target.value === "."
                          ) {
                            formik.setFieldValue("address", "");
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Autocomplete>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Start Date</Typography>
                  <TextField
                    hiddenLabel
                    type={"date"}
                    name="startDate"
                    variant="outlined"
                    fullWidth
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                    helperText={formik.touched.startDate && formik.errors.startDate}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">End Date</Typography>
                  <TextField
                    hiddenLabel
                    type={"date"}
                    name="endDate"
                    variant="outlined"
                    fullWidth
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                    helperText={formik.touched.endDate && formik.errors.endDate}
                  />
                </Grid>
                {/* {!_id&& <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Select Agent</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="agent"
                      value={formik.values.agent}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.agent && Boolean(formik.errors.agent)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Agent
                      </MenuItem>
                      {agents.map((it) => (
                        <MenuItem key={it._id} value={it._id}>
                          {it.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.agent && formik.errors.agent && (
                      <Typography variant="caption" color="error">
                        {formik.errors.agent}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>} */}
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Property Type</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="propertyType"
                      value={formik.values.propertyType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.propertyType && Boolean(formik.errors.propertyType)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="1">Buy</MenuItem>
                      <MenuItem value="2">Rent</MenuItem>
                    </Select>
                    {formik.touched.propertyType && formik.errors.propertyType && (
                      <Typography variant="caption" color="error">
                        {formik.errors.propertyType}
                      </Typography>
                    )}
                  </FormControl>
                </Grid> */}
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">Category</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="category"
                      value={formik.values.category}
                      onChange={handleCategoryChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.category && Boolean(formik.errors.category)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select the category
                      </MenuItem>
                      {categories.map((category) => (
                        <MenuItem key={category._id} value={category._id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.category && formik.errors.category && (
                      <Typography variant="caption" color="error">
                        {formik.errors.category}
                      </Typography>
                    )}
                  </FormControl>
                </Grid> */}

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Link</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="link"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Link"
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.link && Boolean(formik.errors.link)}
                    helperText={formik.touched.link && formik.errors.link}
                  />
                </Grid>

              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary" disabled={formik.isSubmitting}>
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddAds;
