import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
} from "@mui/material";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
  BarChart,
  Legend,
  Bar,
  CartesianGrid,
  Rectangle,
} from "recharts";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment";
import { Autocomplete } from "@react-google-maps/api";
import useGetFilters from "../../hooks/useGetFilter";
import { useLazyGetListingPerformancesQuery } from "../../services/insights";
import { useParams } from "react-router-dom";
const showDecimalValue = (value: string) => {
  return value.toString().split(".")?.length > 1
    ? parseFloat(value).toFixed(2)
    : value;
};
const PerformanceOverview: any = () => {
  const { _id: id } = useParams();
  const [insights] = useLazyGetListingPerformancesQuery();
  const { cat, propertyType, condition }: any = useGetFilters();
  console.log("condition :", condition);
  console.log("propertyType :", propertyType);
  console.log("cat :", cat);
  const [month, setMonth] = useState(new Date());
  const [graphType, setGraphType] = useState<number | undefined>(5);
  const [location, setLocation] = useState("");

  const [filter, setFilter] = useState({
    category: "",
    type: "",
    status: "",
    location: {
      lat: "",
      lng: "",
    },
    graphType: "monthly",
  });

  const [graphDataNew, setGraphDataNew] = useState<any>([]);
  const [selectedChip, setSelectedChip] = useState("");
  const [autocomplete, setAutocomplete] = useState(null); // state to store the autocomplete for the google places api

  const [values, setValues] = useState({
    averageLeadsPerListing: 0,
    creditsSpent: 0,
    impressions: 0,
    listingClicks: 0,
    liveListings: 0,
    publishedListings: 0,
    totalLeads: 0,
  });

  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const [open, setOpen] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  // loading fucntion for the google places api
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  // Function to listen the google places api changes
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();
      console.log("place :", place);

      if (place) {
        let lat = place.geometry.location.lat();
        let lng = place.geometry.location.lng();
        setLocation(place?.formatted_address);
        setFilter({
          ...filter,
          location: {
            lat,
            lng,
          },
        });
      }
    }
  };
  const resetFilter = () => {
    setFilter({
      category: "",
      type: "",
      status: "",
      location: {
        lat: "",
        lng: "",
      },
      graphType: "monthly",
    });
    setGraphType(5);
    setLocation("")
    setRange({from:undefined,to:undefined})

  };
  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const numbers = [
    {
      label: "Credit spent",
      value: showDecimalValue(`${values?.creditsSpent || 0}`),
      graphType: 5,
    },
    {
      label: "Published Listing",
      value: showDecimalValue(`${values?.publishedListings || 0}`),
      graphType: 3,
    },
    {
      label: "Live Listing",
      value: showDecimalValue(`${values?.liveListings || 0}`),
      graphType: 1,
    },
    {
      label: "Listing click",
      value: showDecimalValue(`${values?.listingClicks || 0}`),
      graphType: 2,
    },
    {
      label: "Impressions",
      value: showDecimalValue(`${values?.impressions || 0}`),
      graphType: 4,
    },

    {
      label: "Leads",
      value: showDecimalValue(`${values?.totalLeads || 0}`),
      graphType: 6,
    },
    {
      label: "Leads per Listing",
      value: showDecimalValue(`${values?.averageLeadsPerListing || 0}`),
      graphType: 7,
    },
  ];

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const getInsights = async () => {
    try {
      const response = await insights({
        graphType: graphType,

        startDate: range?.from
          ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: range?.to
          ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        categoryId: filter?.category,
        typeId: filter?.type,
        lat: Number(filter?.location?.lat || "0"),
        lng: Number(filter?.location?.lng || "0"),
        propertyConditionId: filter?.status,
        creditsType: graphType === 5 ? filter.graphType : undefined,
        publishGraph: graphType !== 5 ? filter.graphType : undefined,
        id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setValues({
          ...values,
          averageLeadsPerListing: response?.data?.averageLeadsPerListing || 0,
          creditsSpent: response?.data?.creditsSpent || 0,
          impressions: response?.data?.impressions || 0,
          listingClicks: response?.data?.listingClicks || 0,
          liveListings: response?.data?.liveListings || 0,
          publishedListings: response?.data?.publishedListings || 0,
          totalLeads: response?.data?.totalLeads || 0,
        });
        setGraphDataNew(response?.data?.graphData?.userData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterChange = (key: string, value: string) => {
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const resultBarChart = useMemo(() => {
    return graphDataNew?.map((item: any) => ({
      name: item.name,
      standard: item.standard,
      featured: item.featured,
      premium: item.premium,
    }));
  }, [graphDataNew]);
  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };
  useEffect(() => {
    getInsights();
  }, [range, filter, graphType]);

  return (
    <div className="listing-insights lstng_insgts">
      <div className="page_heading main">
        <h1>Performance Overview</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
          perferendis maiores deserunt dignissimos rerum ut placeat porro maxime
          earum odio labore reiciendis dolor voluptatibus soluta nemo, est
          repudiandae officia totam.
        </p>
      </div>
      <div className="filters">
        <div className="control_group">
          <Select
            labelId="category-label"
            id="category-select"
            value={filter.category}
            onChange={(value) => {
              handleFilterChange("category", value.target.value);
            }}
            displayEmpty
          >
            <MenuItem value={""} disabled>
              Property category
            </MenuItem>
            {cat?.category?.map((item: any, index: any) => (
              <MenuItem key={item?.id} value={item?._id}>
                {item?.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="control_group">
          <Select
            labelId="category-label"
            id="category-select"
            value={filter.type}
            onChange={(value) => {
              handleFilterChange("type", value.target.value);
            }}
            displayEmpty
          >
            <MenuItem value={""} disabled>
              Property Type
            </MenuItem>
            {propertyType?.propertyType?.map((item: any, index: any) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.name || ""}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="control_group">
          <Select
            labelId="category-label"
            id="category-select"
            value={filter.status}
            onChange={(value) => {
              handleFilterChange("status", value.target.value);
            }}
            displayEmpty
          >
            <MenuItem value={""} disabled>
              Property Status
            </MenuItem>
            {condition?.PropertyCondition?.map((item: any, index: any) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.name || ""}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="control_group">
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            options={{
              types: ["political"],
              componentRestrictions: { country: "ae" },
          }}
          >
            <TextField
              className="text_field"
              hiddenLabel
              name="address"
              placeholder="Address"
              value={location}
              fullWidth
              inputProps={{
                maxLength: 100,
              }}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                  setFilter({
                    ...filter,
                    location: {
                      lat: "",
                      lng: "",
                    },
                  });
                } else {
                  setLocation(val.target.value);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </Autocomplete>
          {/* </Select> */}
        </div>
        <div className="control_group">
          <TextField
            // label="Select Date Range"
            variant="outlined"
            hiddenLabel
            className="text_field-range"
            fullWidth
            onClick={handleClick}
            value={getFormattedRange()}
            placeholder="Select Date Range"
            InputProps={{
              readOnly: true, // Make it readonly
            }}
          />
        </div>
        <div className="control_group">
          <Select
            labelId="category-label"
            id="category-select"
            value={filter.graphType}
            onChange={(value) => {
              handleFilterChange("graphType", value.target.value);
            }}
            displayEmpty
          >
            <MenuItem value={""} disabled>
              Graph Type
            </MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="yearly">Yearly</MenuItem>
          </Select>
        </div>
        <Button onClick={resetFilter} component="a">Clear</Button>
      </div>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
            <div
              style={{
                padding: 8,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "8px",
                }}
              >
                <Chip
                  label="Last Week"
                  variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                  onClick={() => handleChipClick("lastWeek")}
                />
                <Chip
                  label="Last Month"
                  variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                  onClick={() => handleChipClick("lastMonth")}
                />
                <Chip
                  label="Last Year"
                  variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                  onClick={() => handleChipClick("lastYear")}
                />
              </div>

              <DayPicker
                className="react-day-picker"
                mode="range"
                selected={range}
                onSelect={handleSelect}
                // numberOfMonths={1}
                month={month}
                onMonthChange={handleMonthChange}
                // styles={{
                //   months: { display: "flex", gap: "16px" },
                //   month: { margin: 0 },
                // }}
              />
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <div className="insight-card">
        <div className="insight-count">
          {numbers?.map((item, index) => (
            <div
              className={`card-number ${
                graphType === item?.graphType ? "selected" : ""
              }`}
              key={index}
              style={{
                cursor: "pointer",
                border: item?.graphType == graphType ? "#000000 2px solid" : "",
              }}
              onClick={() => setGraphType(item?.graphType)}
            >
              <h4>{item?.value}</h4>
              <p>{item?.label}</p>
            </div>
          ))}
        </div>

        <div className="page_heading">
          <h3>Credit Spent</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
            perferendis maiores deserunt dignissimos rerum ut placeat
          </p>
        </div>

        <div className="insight_graph">
          <div className="insight_graph_item bx_shadow">
            <ResponsiveContainer>
              <AreaChart data={graphDataNew}>
                <defs>
                  <linearGradient id="colorCredit" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="grayGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#cccccc" stopOpacity={0.8} />
                    <stop offset="100%" stopColor="#eeeeee" stopOpacity={0} />
                  </linearGradient>
                </defs>
                {/* <Line type="monotone" dataKey="uv" stroke="#8884d8" /> */}
                <XAxis dataKey="name" width={10} />
                <YAxis
                  tick={{ fill: "#000000" }}
                  stroke="black"
                  tickSize={20}
                />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="count"
                  stroke="#8884d8"
                  fill="url(#grayGradient)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div className="insight_graph_item bx_shadow">
            <ResponsiveContainer>
              <BarChart
                data={resultBarChart}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                // payload={barGraphData?.map((item, index) => ({
                //   id: item.name,
                //   color: colors[index],
                //   type: "square",
                //   value: item.name,
                // }))}
                />

                <Bar
                  dataKey="standard"
                  fill="#8884d8" // Color for standard bars
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
                <Bar
                  dataKey="featured"
                  fill="#82ca9d" // Color for featured bars
                  activeBar={<Rectangle fill="gold" stroke="purple" />}
                />
                <Bar
                  dataKey="premium"
                  fill="#ff7300" // Different color for premium bars
                  activeBar={<Rectangle fill="#ff7300" stroke="purple" />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceOverview;
