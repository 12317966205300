import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};


export const feedbackApis = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postFeedbackApi: builder.mutation<
            CommonResponseType & { data: any },
            { body: CommonBody, id: string }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.feedback}/${id}/${END_POINTS.reply}`,
                method: "PUT",
                body,
            }),
        }),
        getAllFeedbacks: builder.query<CommonResponseType & { data: any }, { page?: number; limit?: number }>({
            query: ({ page, limit }) => {
                let url = `${END_POINTS.feedback}?page=${page}&limit=${limit}`;
                return {
                    url: url,
                    method: "GET",
                };
            },
        }),




    }),
});

export const {
    usePostFeedbackApiMutation,
    useLazyGetAllFeedbacksQuery

} = feedbackApis;
