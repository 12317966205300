import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { generateResponsiveStyle } from "../utils/modalStyle";
import { usePostFeedbackApiMutation } from "../services/feedback";
import Loader from "../helpers/constants/Loader";
import { errorToast, successToast } from "../helpers";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    id: string;
};

const RevertQueryModal = ({ open, setOpen, id }: props) => {
    const style = generateResponsiveStyle();
    const [review, setReview] = useState<string>("")
    const [postRevert, { isLoading }] = usePostFeedbackApiMutation();

    const postReply = async () => {
        const body = {
            message: review
        }
        try {
            const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            const res = await postRevert({
                body: encryptedBody,
                id: id
            }).unwrap();
            if (res?.statusCode === 200) {
                successToast(res?.data?.message || "Revert sent successfully")
                setOpen(false)
            }
        } catch (error: any) {

            errorToast(error?.data?.message)
            setOpen(false)
        }
    }

    return (
        <>
            <Loader isLoad={isLoading} />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ position: "relative", marginTop: 20 }}>
                        <div className="cross_icn_logout" style={{ paddingTop: 22 }}>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{ textAlign: "center", fontSize: "19px", marginBottom: "20px", padding: "10px" }}
                        >
                            Send a Revert message to User
                        </Typography>
                        <TextField
                            className="text_field"
                            hiddenLabel
                            placeholder="Type here.."
                            fullWidth
                            type="text"
                            variant="outlined"
                            multiline
                            minRows={3}
                            value={review}
                            onChange={(e) => setReview(e.target.value)}

                        />
                        <div className="flexDiv" style={{ justifyContent: "center", marginTop: 20 }}>
                            <Button
                                disabled={review === ""}
                                sx={{
                                    backgroundColor: "grey",
                                    color: "black",
                                    textTransform: "capitalize",
                                    marginRight: 2,
                                    "&:hover": {
                                        backgroundColor: "black",
                                        color: "white",
                                    },
                                }}
                                onClick={() => {
                                    postReply()
                                }}
                            >
                                Send
                            </Button>

                            <Button
                                sx={{
                                    backgroundColor: "grey",
                                    color: "black",
                                    textTransform: "capitalize",
                                    "&:hover": {
                                        backgroundColor: "black",
                                        color: "white",
                                    },
                                }}
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default RevertQueryModal;
