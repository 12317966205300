import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { generateResponsiveStyle } from "../utils/modalStyle";

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    details: string;
};

const FeedbackDetails = ({ open, setOpen, details }: props) => {
    const style = generateResponsiveStyle();

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ position: "relative", marginTop: 20 }}>
                    <div className="cross_icn_logout" style={{ paddingTop: 22 }}>
                        <CloseIcon onClick={() => setOpen(false)} />
                    </div>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ textAlign: "center", fontSize: "19px" }}
                    >
                        {details || "No feedback message available"}
                    </Typography>
                    <div className="flexDiv" style={{ justifyContent: "center", marginTop: 20 }}>
                        <Button
                            sx={{
                                backgroundColor: "grey",
                                color: "black",
                                textTransform: "capitalize",
                                marginRight: 2,
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Close
                        </Button>


                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default FeedbackDetails;
