import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { usePostAddNotificatiosnMutation,
  useLazyGetAllNotificatiosnQuery,
  useLazyGetSpecificNotificationByIdQuery,
  useLazyDeleteByIdNotificationQuery,
  useLazyRcvdAllNotificatiosQuery,
  useEditNotificationMutation} from "../../services/notifications";
import { errorToast, successToast } from "../../helpers";
 
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Pagination from "../../components/Pagination";


const RecievedNotifications = () => {
  const[getAllNotifications,{isLoading}]=useLazyRcvdAllNotificatiosQuery();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const[deleteNotificationById]=useLazyDeleteByIdNotificationQuery();
  const [totalCount, setTotalCount] = useState<number>(0);
   const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const[rows,setRows]=useState<any>([]);
 
 
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
 
 
  const fetchData = async (page: number, search: string) => {
    try {
      const response = await getAllNotifications({ page:page, search:search,limit:limit }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data[0]?.data||[]);
        setTotalCount(response?.data[0]?.total);
      } 
    } catch (error:any) {
      console.error(error);
      errorToast(error?.message);
    }
  };
  const handleDeleteRcvdNotification = async (userId: any) => {
    try {
      const response = await deleteNotificationById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Notification deleted successfully");
        fetchData(page, debouncedSearchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm,limit]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Received Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer Email</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length == 0 ? (
              <TableRow>
               <TableCell colSpan={6} align="center">
                No notification received 
              </TableCell>
              </TableRow>
               ) : (rows.map((row:any, i:any) => (
                  <TableRow>
                    <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                    <TableCell>{row?.name||"-"}</TableCell>
                    <TableCell>{row?.email||"-"}</TableCell>
                    <TableCell>{row?.title||"-"}</TableCell>
                    <TableCell>{row?.message||"-"}</TableCell>
                    <TableCell>
                      <Box className="table_actions">
                      <IconButton onClick={() => {
            setOpen(true);
            setSelectedId(row?._id);
          }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          limit={limit}
          setLimit={setLimit}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteRcvdNotification(selectedId)}
        name="Notification"
      />
    </MainContainer>
  );
};

export default RecievedNotifications;
